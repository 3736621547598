import React, { useState, useEffect } from "react";
import { InView } from "react-intersection-observer";
import { LazyLoadImage } from "react-lazy-load-image-component";

const OneBurn = ({
  item,
  openModalPartners,
  index,
  allUserCanBuySpnLocal,
  oneListSale,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibilityChange = (inView) => {
    setIsVisible(inView);
  };

  return (
    // <InView as="div" onChange={handleVisibilityChange}>
    //   <div className={`lines ${isVisible ? "visible" : "hidden"}`}>
    //       {/* Ваш контент */}
    //   </div>
    // </InView>
    <InView
      as="div"
      onChange={handleVisibilityChange}
      // rootMargin="100px" threshold="1"
    >
      <div className={`cardPartFirst ${index} ${item.name}`}>
        <div className="bodyCardFirst">
          {isVisible ? (
            <div className={`lines ${isVisible ? "show" : "hide"}`}>
              <div className="left l1">
                <span></span>
              </div>
              <div className="left l2">
                <span></span>
              </div>
              <div className="left l3">
                <span></span>
              </div>
              <div className="left l4">
                <span></span>
              </div>
              <div className="left l5">
                <span></span>
              </div>

              <div className="right l6">
                <span></span>
              </div>
              <div className="right l7">
                <span></span>
              </div>
              <div className="right l8">
                <span></span>
              </div>
              <div className="right l9">
                <span></span>
              </div>
            </div>
          ) : null}

          <div
            className="iconNftPart loop-ico burn-img"
            // style={{
            //     backgroundImage: `url(https://cloudflare-ipfs.com/ipfs/${item.data.img})`
            // }}
          >
            <LazyLoadImage src={`/img/tmpl/${item.tmpl}.webp`} effect="blur" />
          </div>

          {/* <div className="fonBlTop"></div> */}

          <div className="fonViolCircl"></div>
        </div>

        <div className="box-descr">
          <div className="box-descr-top">
            <div className="box-descr-left">
              <span>{item.name} Box</span>
              <i>
                Available {oneListSale.count}/{item.available}
              </i>
            </div>
            <div className="box-descr-right">
              <span>Drop price</span>
              <i className="coin">{item.price}</i>
            </div>
          </div>

          <div className="box-descr-bot">
            <div className="burn-count">
              <span>Burned</span>
              <b>
                {allUserCanBuySpnLocal && allUserCanBuySpnLocal.length
                  ? allUserCanBuySpnLocal[index]
                  : 0}
              </b>
            </div>
            <div className="burn-text">
              Burn {item.name} Premium Access for a guaranteed Starter Box claim
            </div>
          </div>
        </div>
        <div className="box-bt">
          <button onClick={() => openModalPartners("open", item.pass, index)}>
            <span>
              Burn
              <i>
                <img src={`/img/saleburn/burn-${item.name}-ico.png`} alt="" />
              </i>
            </span>
          </button>
        </div>
      </div>
    </InView>
  );
};

export default OneBurn;
