import React from "react";
import "./Spinner.css";
import { useState, useEffect, useRef, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import ModalListSpinney from "../../Modal/ModalListSpinney";
import Countdown from "react-countdown";
import { mult } from "./../../../components/Wax/Variables";
// import ModalListRuna from '../../Modal/ModalListRuna';
// import Header from '../../Header/Header';
import ModalUnstakeSpinney from "../../Modal/ModalUnstakeSpinney";
import { spin } from "./../../../components/Wax/Transactions";
import { UALContext } from "ual-reactjs-renderer";
import { ToastContainer, toast } from "react-toastify";
import useGetUserCollectionInfo from "./../../Wax/useGetUserCollectionInfo";

//import Lottie from "lottie-react";
// import animationData from '../../AnimationData/New common 400x400 (1).json';
// import animationData2 from '../../AnimationData/04 New Rare 400x400.json';
// import animationData4 from '../../AnimationData/New Uncommon 400x400.json';
// import animationData5 from '../../AnimationData/05 New Epic 400x400.json';
// import animationData6 from '../../AnimationData/06 New Legend 400x400.json';
import commonAnim from "../../../img/spinneyAnim/Common.png";
import epicAnim from "../../../img/spinneyAnim/Epic.png";
import legendAnim from "../../../img/spinneyAnim/Legend.png";
import rareAnim from "../../../img/spinneyAnim/Rare.png";
import uncommonAnim from "../../../img/spinneyAnim/Uncommon.png";
import promoAnim from "../../../img/spinneyAnim/Promo.png";
import jinglesAnim from "../../../img/spinneyAnim/Jingles.png";

import iconPromo from "../../StopAnimation/01 New promo .png";
import iconCommon from "../../StopAnimation/02 New common.png";
import iconUncomon from "../../StopAnimation/03 New uncommon.png";
import iconRare from "../../StopAnimation/04 New Rare.png";
import iconEpic from "../../StopAnimation/05 (Epic) Violetta.png";
import iconLegend from "../../StopAnimation/06 New Legend.png";
import iconJingles from "../../StopAnimation/01 (Prommo) Jingles_00051.png";

import iconPromoRotat from "../../StopAnimation/01 New  prommo Rotating.png";
import iconCommonRotat from "../../StopAnimation/02 New common Rotating.png";
import iconUncomonRotat from "../../StopAnimation/03 New uncommon Rotating.png";
import iconRareRotat from "../../StopAnimation/04 New Rare Rotating.png";
import iconEpicRotat from "../../StopAnimation/05 (Epic) Violetta Rotating.png";
import iconLegendRotat from "../../StopAnimation/06 New Legend Rotating.png";
import iconJingesRotat from "../../StopAnimation/01 (Prommo) Jingles_00051 Rotating.png";

const formatTime = (time) => (time < 10 ? `0${time}` : time);
const UniversalSpin = ({
  props,
  selectedSlide,
  setSelectedSlide,
  children,
  addSelectedObject,
  activeSpinner,
  showCanvas,
  activeBtnBurn,
  id,
  handleSpinAllClick,
  activeSpinnerForRuna,

  //spinTrans,
}) => {
  const ual = useContext(UALContext);
  const [stopAnimation, setStopAnimation] = useState(false);
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [isClicked, setIsClickeds] = useState(false);
  const [firstDivActive, setFirstDivActive] = useState(false);
  const [localActiveSpiner, setLocalctiveSpiner] = useState(activeSpinner);
  const [modal6Active, setModal6Active] = useState(false);
  const [modal2Active, setModal2Active] = useState(false);
  const [spinSpeedPayment, setSpinSpeedPayment] = useState();
  const [spinSpeedBuffer, setSpinSpeedBuffer] = useState(
    localActiveSpiner.speed
  );
  const [allSumSpinsPayment, setAllSumSpinsPayment] = useState();
  const [allSumSpinsBuffer, setAllSumSpinsBuffer] = useState(
    localActiveSpiner.spins
  );
  const [spinCount, setSpinCount] = useState();
  const [vip, setVip] = useState();
  const [userMult, setUserMult] = useState();
  const [runa, setRuna] = useState();
  const [vipCredit, setVipCredit] = useState();
  const [activeButtonSpin, setActiveButtonSpin] = useState(
    localActiveSpiner.rarity
  );
  const [score, setScore] = useState(localActiveSpiner.storage / 10000);
  const [leftSecondToNextLevel, setLeftSecondToNextLevel] = useState(1);
  //const [timeSpend, setTimeSpend] = useState(0);

  const lottieRef = useRef(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const canvasRef = useRef(null);

  const [specRunes, setSpecRunes] = useState(null);

  const [infoUser, setInfoUser] = useState({});
  const { userColInfo } = useGetUserCollectionInfo(props.userInGameState?.user);
  useEffect(() => {
    if (
      userColInfo &&
      userColInfo.user !== undefined &&
      infoUser.user === undefined
    ) {
      setInfoUser(userColInfo);
    }
  }, [userColInfo]);

  // const mult = [
  //   [0, 1.1, 1.325, 1.55, 1.775, 2],
  //   [0, 1.1, 1.325, 1.55, 1.775, 2],
  //   [0, 1.1, 1.35, 1.6, 1.85, 2.1],
  //   [0, 1.1, 1.375, 1.65, 1.925, 2.2],
  //   [0, 1.1, 1.4, 1.7, 2, 2.3],
  //   [0, 1.1, 1.45, 1.8, 2.15, 2.5]
  // ];

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    const frameWidth = 400;
    const frameHeight = 400;
    const rows = 10;
    let stopFrame;

    let mario = {
      img: new Image(),
      width: frameWidth,
      height: frameHeight,
      currentframe: 0,
    };

    const animSources = [
      promoAnim,
      commonAnim,
      uncommonAnim,
      rareAnim,
      epicAnim,
      legendAnim,
      jinglesAnim,
    ];
    const stopFrameRar = [0, 0, 50, 50, 50, 50, 50];
    mario.img.src = animSources[localActiveSpiner.rarity] || commonAnim;
    stopFrame = stopFrameRar[localActiveSpiner.rarity];

    let totalframes;

    mario.img.onload = function () {
      totalframes = (mario.img.width / frameWidth) * rows;
      if (isAnimating) requestAnimationFrame(frame);
      else drawFrame(stopFrame);
    };

    function drawFrame(frame) {
      const col = frame % (totalframes / rows);
      const row = Math.floor(frame / (totalframes / rows));
      const sourceX = col * frameWidth;
      const sourceY = row * frameHeight;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(
        mario.img,
        sourceX,
        sourceY,
        frameWidth,
        frameHeight,
        0,
        0,
        frameWidth,
        frameHeight
      );
    }

    let lastTimestamp;
    function frame(timestamp) {
      if (!lastTimestamp) lastTimestamp = timestamp;
      const elapsed = timestamp - lastTimestamp;

      if (isAnimating) {
        if (elapsed > 1000 / 70) {
          mario.currentframe = (mario.currentframe + 1) % totalframes;
          drawFrame(mario.currentframe);
          lastTimestamp = timestamp;
        }
        requestAnimationFrame(frame);
      } else {
        drawFrame(stopFrame);
      }
    }
  }, [isAnimating, showCanvas]);

  const spinTrans = async (asset_id, totalReward, countdown) => {
    try {
      setIsClickeds(true);
      const { activeUser } = ual;
      const { accountName } = activeUser;

      const transactionPayload = spin(accountName, [asset_id]);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      var newSpin = localActiveSpiner.spins;
      var newSpeed = localActiveSpiner.speed;
      var newSpeedUp = localActiveSpiner.speed_up;

      if (localActiveSpiner.spins == newSpeedUp - 1) {
        var x = localActiveSpiner.speed;
        if (x < 5) {
          newSpeed += 1;
        }
        newSpin = 0;
        //rec.mult = mult[itr_spiner -> rarity][x]; // тут проверить !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      } else {
        newSpin += 1;
      }

      var totalRewardNew;
      if (vip) {
        var mult_bonus = props.userInGameState.mult_bonus;
        var ref_bonus = props.userInGameState.ref_bonus;
        var part_bonus = infoUser?.mult || 1;

        if (part_bonus > 1.1) {
          part_bonus = 1.1;
        }

        var sum_bonus =
          parseFloat(mult_bonus) +
          parseFloat(ref_bonus) +
          parseFloat(part_bonus) -
          3;

        if (localActiveSpiner.speed == 1) {
          sum_bonus = 0;
        } else if (localActiveSpiner.speed == 2) {
          sum_bonus = sum_bonus * 0.25;
        } else if (localActiveSpiner.speed == 3) {
          sum_bonus = sum_bonus * 0.5;
        } else if (localActiveSpiner.speed == 4) {
          sum_bonus = sum_bonus * 0.75;
        } else if (localActiveSpiner.speed == 5) {
          sum_bonus = sum_bonus;
        }

        //console.log("sum_bonus", sum_bonus)
        totalRewardNew =
          parseFloat(localActiveSpiner.cons) *
          ((parseFloat(localActiveSpiner.mult) - 1 + sum_bonus) *
            process.env.REACT_APP_DEF +
            1);

        //earned_money = itr_spiner->cons * ((itr_spiner->mult - 1 + sum_bonus) * def_const + 1) * 10000;
        //new_score += itr_spiner->cons * ((mult[itr_spiner->rarity][spiner_speed_for_payment] - 1 + sum_bonus)  * def_const + 1);
      } else {
        totalRewardNew =
          parseFloat(localActiveSpiner.cons) *
          ((parseFloat(localActiveSpiner.mult) - 1) *
            process.env.REACT_APP_DEF +
            1);
      }

      //console.log("totalRewardNew", totalRewardNew)

      const updatedSpinner = {
        ...localActiveSpiner,
        spins: newSpin,
        speed: newSpeed,
        storage: localActiveSpiner.storage + parseInt(totalRewardNew * 10000),
        timer: Math.floor(Date.now() / 1000) + parseInt(countdown),
        mult: mult[localActiveSpiner.rarity][newSpeed],
        //timer: Math.floor(Date.now() / 1000) + 1
      };

      setLocalctiveSpiner(updatedSpinner);
      setSpinSpeedBuffer(newSpeed);
      props.balanceDisp(false, localActiveSpiner.cons);

      // setAllSinnersInGameLocal(prevState => ({
      //   ...prevState,
      //   all_slot: prevState.all_slot.push(asset_id)
      // }));

      toast("Transaction ID: " + transaction?.transactionId);
    } catch (e) {
      toast(e.toString());
      console.log(e);
      // if (e instanceof RpcError)
      //   console.log(JSON.stringify(e.json, null, 2));
    }
  };

  useEffect(() => {
    setUserMult(props.userInGameState.mult_bonus);
  }, []);

  useEffect(() => {
    //console.log("allSpecRunesInGame", props.allSpecRunesInGame)
    if (
      props.allSpecRunesInGame &&
      props.allSpecRunesInGame.length &&
      localActiveSpiner
    ) {
      //console.log("localActiveSpiner", localActiveSpiner)
      const item = props.allSpecRunesInGame.find(
        (item) => item.spinner_id === localActiveSpiner.asset_id
      );
      setSpecRunes(item);
      //console.log("item", item);
    }
  }, [props.allSpecRunesInGame]);

  useEffect(() => {
    setLocalctiveSpiner(activeSpinner);
    setSpinSpeedBuffer(activeSpinner?.speed);
    //console.log("AFTER TRANS");
  }, [activeSpinner, props.allSpecRunesInGame]);

  useEffect(() => {
    setActiveButtonSpin(localActiveSpiner.rarity);
    setScore(localActiveSpiner.storage / 10000);
    setSpinSpeedPayment(localActiveSpiner.speed);
    setAllSumSpinsPayment(localActiveSpiner.spins);
    setAllSumSpinsBuffer(localActiveSpiner.spins);
    setSpinCount(localActiveSpiner.spins);
    setVip(localActiveSpiner.vip);
    setRuna(localActiveSpiner.runes);
    setSpinSpeedBuffer(localActiveSpiner.speed);
    setIsAnimating(
      localActiveSpiner.vip &&
        localActiveSpiner.runes !== 0 &&
        props.reallSpendCredit == 0
        ? true
        : false
    );
  }, [localActiveSpiner]);

  const runaData = [
    {
      className: "promo_runa__0",
      classNameLeft: "promo_item__0",
      // value: '192',
      // valueLeft: '96',
      speed1: "1,325",
      speed2: "",
      speed3: "1,55",
      speed4: "1,775",
      speed5: "2",
      color: "promo__0",
      runaBg: "promo__runaBg",
      runaOpen: "runa_promo__0",
      stakeRuna: "promo__bg",
      pap: "0.1",
      papNoVip: "0",
      papPlusRuna: "0.3",
      soul: "soulPromo",
    },
    {
      className: "common_runa__1",
      classNameLeft: "common_item__1",
      // value: 960,
      // valueLeft: 480,
      speed1: "1,325",
      speed2: "",
      speed3: "1,55",
      speed4: "1,775",
      speed5: "2",
      color: "common__1",
      runaBg: "common__runaBg",
      runaOpen: "runa_common__1",
      stakeRuna: "common__bg",
      pap: "0.5",
      papNoVip: "0",
      papPlusRuna: "1.5",
      soul: "soulCommon",
    },
    {
      className: "uncommon_runa__2",
      classNameLeft: "uncommon_item__2",
      // value: 1920,
      // valueLeft: 960,
      speed1: "1,35",
      speed2: "",
      speed3: "1,6",
      speed4: "1,85",
      speed5: "2,1",
      color: "uncommon__2",
      runaBg: "uncommon__runaBg",
      runaOpen: "runa_uncomon__4",
      stakeRuna: "uncommon__bg",
      pap: "1",
      papNoVip: "0",
      papPlusRuna: "3",
      soul: "soulUncom",
    },
    {
      className: "frosty_runa__3",
      classNameLeft: "rare_item__3",
      // value: 3840,
      // valueLeft: 1920,
      speed1: "1,375",
      speed2: "",
      speed3: "1,65",
      speed4: "1,925",
      speed5: "2,2",
      color: "rare__3",
      runaBg: "rare__runaBg",
      runaOpen: "runa_rare__2",
      stakeRuna: "runa",
      pap: "2",
      papNoVip: "0",
      papPlusRuna: "6",
      soul: "soulRare",
    },
    {
      className: "violetta_runa__4",
      classNameLeft: "epic_item__4",
      // value: 7680,
      // valueLeft: 3840,
      speed1: "1,4",
      speed2: "",
      speed3: "1,7",
      speed4: "2",
      speed5: "2,3",
      color: "epic__4",
      runaBg: "violetta__runaBg",
      runaOpen: "runa_epic__3",
      stakeRuna: "violetta__bg",
      pap: "4",
      papNoVip: "0",
      papPlusRuna: "12",
      soul: "soulViolet",
    },
    {
      className: "sunshine_runa__5",
      classNameLeft: "legend_item__5",
      // value: 15360,
      // valueLeft: 7680,
      speed1: "1,45",
      speed2: "",
      speed3: "1,8",
      speed4: "2,15",
      speed5: "2,5",
      color: "sunshine__5",
      runaBg: "sunshine__runaBg",
      runaOpen: "runa_legend__5",
      stakeRuna: "sunshine__bg",
      pap: "8",
      papNoVip: "0",
      papPlusRuna: "24",
      soul: "soulLegend",
    },
    {
      className: "",
      classNameLeft: "jing_item__6",
      // value: '192',
      // valueLeft: '96',
      speed1: "1,325",
      speed2: "",
      speed3: "1,55",
      speed4: "1,775",
      speed5: "2",
      color: "promo__0",
      runaBg: "promo__runaBg",
      runaOpen: "runa_promo__0",
      stakeRuna: "promo__bg",
      pap: "0.1",
      papNoVip: "0",
      papPlusRuna: "0.3",
      soul: "soulPromo",
    },
  ];

  const activeRuna = runaData.find((runa, index) => index === activeButtonSpin);

  let intervalRef;

  const calculateInfinity = () => {
    if (props.reallSpendCredit == 0) {
      setIsAnimating(true);
    }

    //const vipcredit = props.userInGameState.vipcredit; // сколько осталось времени
    let vipcredit = props.allRunesWorkDays;

    const activespin = props.userInGameState.activespin; // сколько активных спинов
    let lastact = localActiveSpiner.lastact; // последний раз когда активыровался спин
    const storageSpiner = localActiveSpiner.storage;

    const interval = setInterval(() => {
      const currentTime = Math.floor(Date.now() / 1000); // текущее время
      let manytime = currentTime - lastact; // сколько времени от текущего до последнего времени активации
      const boofsec = localActiveSpiner.boofsec; // сколько времени осталось для повішения кручения

      if (localActiveSpiner.runes == "111111111111111") {
        if (specRunes && specRunes !== null) {
          if (specRunes.endtime > currentTime) {
            manytime = currentTime - lastact;
          } else {
            if (specRunes.endtime > lastact) {
              manytime = specRunes.endtime - lastact;
            } else {
              manytime = 0;
            }
          }
        }
      }

      let spinSpeedPaymentLocal = localActiveSpiner.speed;

      let spinSpeedBufferLocal = localActiveSpiner.speed;
      let userMultLocal = parseFloat(props.userInGameState.mult_bonus);
      let userMultRefLocal = parseFloat(props.userInGameState.ref_bonus);
      let userMultBonus = infoUser?.mult || 1;

      if (userMultBonus > 1.1) {
        userMultBonus = 1.1;
      }

      console.log("userMultBonus", userMultBonus);
      console.log("infoUser", infoUser);

      let oneSpendPoint = 0;

      let timePerCard; // делим время для каждого спинера поровну потому что при включении/отключении вип для карты мы их уравниваем

      let day_lastact = Math.floor(lastact / 86400);
      let day_currentTime = Math.floor(currentTime / 86400);

      if (props.reallSpendCredit > 0) {
        oneSpendPoint = vipcredit / activespin;
        let seconds_passed_today = Math.floor(lastact % 86400);
        let seconds_to_midnight = 86400 - seconds_passed_today;
        timePerCard = seconds_to_midnight + oneSpendPoint * 86400;
      } else if (
        /*allLeftPoints < vipcredit ||*/ (day_lastact == day_currentTime &&
          vipcredit == 0) ||
        props.reallSpendCredit == 0
      ) {
        timePerCard = manytime;
      }

      let full_periods_payment = timePerCard / localActiveSpiner.countdown; // полный период от момента когда спинет был поставлен по часам
      const remaining_seconds_payment =
        timePerCard % localActiveSpiner.countdown; // сколько лишних секунд

      let full_periods_buffer =
        (timePerCard + boofsec) / localActiveSpiner.countdown; // полный период от момента когда спинет был поставлен по часам
      const remaining_seconds_buffer =
        (timePerCard + boofsec) % localActiveSpiner.countdown; // сколько лишних секунд

      let allSumSpinsPaymentLocal = localActiveSpiner.spins;
      let allSumSpinsBufferLocal = localActiveSpiner.spins;

      let new_score = storageSpiner / 10000; // переменная для сумирования заработных СПН

      while (Math.floor(full_periods_payment) > 0) {
        let sum_bonus = 0;

        sum_bonus = userMultLocal + userMultRefLocal + userMultBonus - 3;

        if (spinSpeedPaymentLocal == 1) {
          sum_bonus = 0;
        } else if (spinSpeedPaymentLocal == 2) {
          sum_bonus = sum_bonus * 0.25;
        } else if (spinSpeedPaymentLocal == 3) {
          sum_bonus = sum_bonus * 0.5;
        } else if (spinSpeedPaymentLocal == 4) {
          sum_bonus = sum_bonus * 0.75;
        } else if (spinSpeedPaymentLocal == 5) {
          sum_bonus = sum_bonus;
        }

        //new_score += localActiveSpiner.cons * (mult[localActiveSpiner.rarity][spinSpeedPaymentLocal] + sum_bonus);
        new_score +=
          localActiveSpiner.cons *
          ((mult[localActiveSpiner.rarity][spinSpeedPaymentLocal] -
            1 +
            sum_bonus) *
            process.env.REACT_APP_DEF +
            1);

        //new_score += itr_spiner->cons * ((mult[itr_spiner->rarity][spiner_speed_for_payment] - 1 + sum_bonus)  * def_const + 1);

        if (allSumSpinsPaymentLocal + 1 == localActiveSpiner.speed_up) {
          allSumSpinsPaymentLocal = 0;
          if (spinSpeedPaymentLocal < 5) {
            spinSpeedPaymentLocal++;
          }
        } else {
          allSumSpinsPaymentLocal++;
        }
        full_periods_payment--;
      }

      while (Math.floor(full_periods_buffer) > 0) {
        if (allSumSpinsBufferLocal + 1 == localActiveSpiner.speed_up) {
          allSumSpinsBufferLocal = 0;
          if (spinSpeedBufferLocal < 5) {
            spinSpeedBufferLocal++;
          }
        } else {
          allSumSpinsBufferLocal++;
        }
        full_periods_buffer--;
      }

      let score_per_second =
        (mult[localActiveSpiner.rarity][spinSpeedPaymentLocal] *
          localActiveSpiner.cons *
          userMultLocal) /
        localActiveSpiner.countdown;

      new_score += score_per_second * remaining_seconds_payment;

      setScore(new_score);

      let leftsec = 0;
      if (
        localActiveSpiner?.runes == "111111111111111" &&
        specRunes &&
        specRunes !== null
      ) {
        // console.log("ffffff")
        // console.log("specRunes.endtime * 1000", specRunes.endtime * 1000)

        if (Date.now() <= specRunes.endtime * 1000) {
          //console.log("qqqqqq")
          leftsec = (specRunes.endtime * 1000 - Date.now()) / 1000;
        }
      } else {
        //console.log("333333")
        leftsec = localActiveSpiner.countdown - remaining_seconds_buffer;
      }

      setAllSumSpinsBuffer(allSumSpinsBufferLocal);
      setSpinSpeedBuffer(spinSpeedBufferLocal);
      setLeftSecondToNextLevel(leftsec);
    }, 1000); // эмуляция начисления каждую секунду

    //return () => clearInterval(interval);
    intervalRef = interval; // сохраните ссылку на интервал

    return interval;
  };

  useEffect(() => {
    setVipCredit(props.allRunesWorkDays);
    if (
      localActiveSpiner.vip &&
      localActiveSpiner.runes !== 0 &&
      props.allLeftPoints !== undefined
      //&& specRunes !== null
      //&& localActiveSpiner.asset_id == 1099908975141
    ) {
      calculateInfinity();
    } else if (!localActiveSpiner.vip && intervalRef) {
      // если vip становится false и интервал существует, остановите его
      clearInterval(intervalRef);
    }

    return () => {
      // Это важно, чтобы очистить интервал, когда компонент размонтируется
      if (intervalRef) {
        clearInterval(intervalRef);
      }
    };
  }, [
    localActiveSpiner,
    props.userInGameState,
    props.allRunesWorkDays,
    specRunes,
    infoUser,
  ]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  let maxClicks = localActiveSpiner.speed_up;
  //let activeSpins = localActiveSpiner.spins;
  let spinDuration =
    (localActiveSpiner.timer - Math.floor(Date.now() / 1000)) * 1000;
  let alredyInStorage = localActiveSpiner.storage / 10000;

  let totalDuration; // 3 минуты в миллисекундах
  let elapsedTime; // сколько времени уже прошло
  let totalReward; // общая награда за 3 минуты
  let earnedReward; // рассчитанная награда за прошедшее время

  useEffect(() => {
    spinDuration =
      (localActiveSpiner.timer - Math.floor(Date.now() / 1000)) * 1000;
    alredyInStorage = localActiveSpiner.storage / 10000;
    totalDuration = localActiveSpiner.countdown * 1000; // 3 минуты в миллисекундах
    elapsedTime = totalDuration - spinDuration; // сколько времени уже прошло
    if (vip) {
      var mult_bonus = props.userInGameState.mult_bonus;
      var ref_bonus = props.userInGameState.ref_bonus;
      var part_bonus = infoUser?.mult || 1;

      if (part_bonus > 1.1) {
        part_bonus = 1.1;
      }
      var sum_bonus =
        parseFloat(mult_bonus) +
        parseFloat(ref_bonus) +
        parseFloat(part_bonus) -
        3;

      if (localActiveSpiner.speed == 1) {
        sum_bonus = 0;
      } else if (localActiveSpiner.speed == 2) {
        sum_bonus = sum_bonus * 0.25;
      } else if (localActiveSpiner.speed == 3) {
        sum_bonus = sum_bonus * 0.5;
      } else if (localActiveSpiner.speed == 4) {
        sum_bonus = sum_bonus * 0.75;
      } else if (localActiveSpiner.speed == 5) {
        sum_bonus = sum_bonus;
      }

      totalReward =
        consumption *
        ((localActiveSpiner.mult - 1 + sum_bonus) * process.env.REACT_APP_DEF +
          1); // общая награда за 3 минуты
      //totalRewardNew = parseFloat(localActiveSpiner.cons) * ((parseFloat(localActiveSpiner.mult) - 1 + sum_bonus) * process.env.REACT_APP_DEF + 1);
    } else {
      totalReward =
        consumption *
        ((localActiveSpiner.mult - 1) * process.env.REACT_APP_DEF + 1); // общая награда за 3 минуты
    }
    earnedReward = (elapsedTime / totalDuration) * totalReward; // рассчитанная награда за прошедшее время

    if (spinDuration > 0) {
      const endReward = alredyInStorage; // итоговая награда
      const startReward = alredyInStorage - totalReward + earnedReward; // начальная награда с учетом уже начисленного
      if (
        (localActiveSpiner.timer > Math.floor(Date.now() / 1000) &&
          localActiveSpiner.runes === 0) ||
        (localActiveSpiner.timer > Math.floor(Date.now() / 1000) &&
          localActiveSpiner.vip &&
          localActiveSpiner.runes === 0) ||
        (localActiveSpiner.timer > Math.floor(Date.now() / 1000) &&
          !localActiveSpiner.vip &&
          localActiveSpiner.runes !== 0) ||
        (localActiveSpiner.timer > Math.floor(Date.now() / 1000) &&
          Date.now() >
            (specRunes && specRunes !== null && specRunes?.endtime * 1000))
      ) {
        animate(startReward, endReward, spinDuration);
        setIsAnimating(true);
      }
    }

    setTotalRewardState(totalReward);
    if (spinDuration > 0) {
      setTimer(true);
    }
  }, [localActiveSpiner]);

  const [timer, setTimer] = useState(false);
  const [totalRewardState, setTotalRewardState] = useState(0);
  const [showFirstInfo, setShowFirstInfo] = useState(false);
  const [showSecondInfo, setShowSecondInfo] = useState(false);
  const [showStorageInfo, setShowStorageInfo] = useState(false);
  const [showSpeedInfo, setShowSpeedInfo] = useState(false);
  const [showOneInfo, setShowOneInfo] = useState(false);
  const [showTwoInfo, setShowTwoInfo] = useState(false);
  const [showThreeInfo, setShowThreeInfo] = useState(false);
  const [showForeInfo, setShowForeInfo] = useState(false);
  const [showFiveInfo, setShowFiveInfo] = useState(false);
  const [showDeactivSpinInfo, setShowDeactivSpinInfo] = useState(false);
  const [showVipInfo, setShowVipInfo] = useState(false);
  const [showNoVipInfo, setShowNoVipInfo] = useState(false);

  const secondInfoClass = `second__infom ${showSecondInfo ? "show" : ""}`;
  const firstInfoClass = `first__infom ${showFirstInfo ? "show" : ""}`;
  const storageInfoClass = `stor__infom ${showStorageInfo ? "show" : ""}`;
  const speedInfoClass = `speed__infom ${showSpeedInfo ? "show" : ""}`;
  const oneInfoClass = `one__infom ${showOneInfo ? "show" : ""}`;
  const twoInfoClass = `two__infom ${showTwoInfo ? "show" : ""}`;
  const threeInfoClass = `three__infom ${showThreeInfo ? "show" : ""}`;
  const foreInfoClass = `fore__infom ${showForeInfo ? "show" : ""}`;
  const fiveInfoClass = `five__infom ${showFiveInfo ? "show" : ""}`;
  const deactivSpinInfoClass = `deactivSpin__infom ${
    showDeactivSpinInfo ? "show" : ""
  }`;
  const vipInfoClass = `vip__infom ${showVipInfo ? "show" : ""}`;
  const noVipInfoClass = `noVip__infom ${showNoVipInfo ? "show" : ""}`;

  const [consumption, setConsumption] = useState(
    parseFloat(localActiveSpiner.cons)
  );

  const storRef = useRef(null);

  useEffect(() => {
    if (timer || (localActiveSpiner.runes && localActiveSpiner.vip !== 0)) {
      if (lottieRef.current) {
        lottieRef.current.play();
      }
    } else {
      if (lottieRef.current) {
        lottieRef.current.pause();
      }
    }

    //console.log("localActiveSpiner", localActiveSpiner)
  }, [localActiveSpiner, timer]);

  useEffect(() => {
    //console.log("lottieRef", lottieRef);
  }, []);

  // ---------------------
  const animate = (start, end, duration) => {
    //console.log(start, end, duration);
    let startTimestamp = null;

    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      const value = start + (end - start) * progress;
      if (storRef.current) {
        storRef.current.innerText = value.toFixed(4).toString();
      }
      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);
  };

  const [isActiveBurn, setIsActiveBurn] = useState();

  const [isActiveSpin, setIsActiveSpin] = useState(6);
  const [isActive, setIsActive] = useState(false);
  const handleEtherClick = () => {
    setIsActive(true);
  };
  const handleStartClick = () => {
    setIsActive(false);
    if (addSelectedObject) {
      addSelectedObject({
        activeButtonSpin: activeButtonSpin,
      });
    }
  };

  const handleSlideClick = (index) => {
    setSelectedSlide(index);
    setIsActiveSpin(true);
  };

  const [showFirstDiv, setShowFirstDiv] = useState(true);

  const handleStakeClick = () => {
    setShowFirstDiv((prevShowFirstDiv) => !prevShowFirstDiv);
  };

  //
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  //

  const buttons = [
    {
      bgClass: "runa promo__bg open-btn",
      btnClass: "runa__noactiv promo__noactiv",
    },
    {
      bgClass: "runa common__bg open-btn",
      btnClass: "runa__noactiv common__noactiv",
    },
    {
      bgClass: "runa uncommon__bg open-btn",
      btnClass: "runa__noactiv uncommon__noactiv",
    },
    {
      bgClass: "runa open-btn",
      btnClass: "runa__noactiv rare__noactiv",
    },
    {
      bgClass: "runa violetta__bg open-btn",
      btnClass: "runa__noactiv violetta__noactiv",
    },
    {
      bgClass: "runa sunshine__bg open-btn",
      btnClass: "runa__noactiv sunshine__noactiv",
    },
    {
      bgClass: "runa promo__bg open-btn",
      btnClass: "runa__noactiv promo__noactiv",
    },
  ];

  const { handleClickAudio } = props;

  const spinButtonRef = useRef(null);

  return (
    <div className="container__blur">
      {/* <div className="fonCardSpinner"></div> */}
      {/* fon winter */}
      {/* <div className="winter_top"></div> */}
      {localActiveSpiner.runes !== 0 && localActiveSpiner.vip !== 0 ? (
        <div className={firstInfoClass}>
          Generate <br />
          <br /> SPN amount spent per spin
        </div>
      ) : (
        <div className={firstInfoClass}>
          Consumption <br />
          <br /> SPN amount spent per spin
        </div>
      )}
      <div className={secondInfoClass}>
        Multiplier
        <br />
        <br />
        This multiplier depends on the Spinney rotation speed.
      </div>
      <div className={storageInfoClass}>
        Storage
        <br />
        <br />
        Storage is replenished every spin.
      </div>
      <div className={speedInfoClass}>
        Next Speed
        <br />
        <br />
        Once the max number of spins is reached, the speed of the Spinney
        increases.
      </div>

      <div className={oneInfoClass}>
        Speed 1<br />
        <br />
        Multiplier 1,1
      </div>
      {/* 2 */}
      <div className={twoInfoClass}>
        Speed 2<br />
        <br />
        Multiplier {activeRuna.speed1}
      </div>
      {/* 3 */}
      <div className={threeInfoClass}>
        Speed 3<br />
        <br />
        Multiplier {activeRuna.speed3}
      </div>

      {/* 4 */}
      <div className={foreInfoClass}>
        Speed 4<br />
        <br />
        Multiplier {activeRuna.speed4}
      </div>

      <div className={deactivSpinInfoClass}>Unstake Spinney</div>
      {/* 5 */}
      <div className={fiveInfoClass}>
        Speed 5<br />
        <br />
        Multiplier {activeRuna.speed5}
      </div>
      <div className={vipInfoClass}>
        Activation
        <br />
        Premium for this spinney
      </div>
      <div className={noVipInfoClass}>
        Deactivation
        <br />
        Premium for this spinney
      </div>
      <div className="content__spinner content__spinner">
        {/* <div className="fonCardLights"></div> */}
        {/* {localActiveSpiner.vip && localActiveSpiner.runes == 0 ? (Math.floor(Date.now() / 1000) - localActiveSpiner.lastacttime) / 86400 : null}
        {localActiveSpiner.vip && localActiveSpiner.runes !== 0 ? (Math.floor(Date.now() / 1000) - props.userInGameState.lastact) / 86400 : null} */}
        {modal6Active ? (
          <ModalUnstakeSpinney
            id={props.id}
            active={props.modalActive}
            setActive={props.setModalActive}
            removeObject={props.removeObject} // Use the removeObject prop from BoxSpinners
          ></ModalUnstakeSpinney>
        ) : null}

        {modal2Active ? (
          <ModalListSpinney
            selectedSlide={selectedSlide}
            setSelectedSlide={setSelectedSlide}
          />
        ) : null}
        <div className="top__menu__name ">
          {activeButtonSpin === 0 && (
            <React.Fragment>
              <div className="SpinneyBox">
                <div id={activeRuna.color} className="boxColorSpinney"></div>
                <div className="">
                  <div className="title">Magmus</div>
                  <div className="param" id="0">
                    {localActiveSpiner.asset_id}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {activeButtonSpin === 1 && (
            <React.Fragment>
              <div className="SpinneyBox">
                <div id={activeRuna.color} className="boxColorSpinney"></div>
                <div className="">
                  <div className="title">Steelade</div>
                  <div className="param" id="1">
                    {localActiveSpiner.asset_id}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {activeButtonSpin === 2 && (
            <React.Fragment>
              <div className="SpinneyBox">
                <div id={activeRuna.color} className="boxColorSpinney"></div>
                <div className="">
                  <div className="title">Leaflynn</div>
                  <div className="param" id="2">
                    {localActiveSpiner.asset_id}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {activeButtonSpin === 3 && (
            <React.Fragment>
              <div className="SpinneyBox">
                <div id={activeRuna.color} className="boxColorSpinney"></div>
                <div className="">
                  <div className="title">Frostee</div>
                  <div className="param" id="3">
                    {localActiveSpiner.asset_id}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {activeButtonSpin === 4 && (
            <React.Fragment>
              <div className="SpinneyBox">
                <div id={activeRuna.color} className="boxColorSpinney"></div>
                <div className="">
                  <div className="title">Violetta</div>
                  <div className="param" id="4">
                    {localActiveSpiner.asset_id}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {activeButtonSpin === 5 && (
            <React.Fragment>
              <div className="SpinneyBox">
                <div id={activeRuna.color} className="boxColorSpinney"></div>
                <div className="">
                  <div className="title">Sunshine</div>
                  <div className="param" id="5">
                    {localActiveSpiner.asset_id}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {activeButtonSpin === 6 && (
            <React.Fragment>
              <div className="SpinneyBox">
                <div id={activeRuna.color} className="boxColorSpinney"></div>
                <div className="">
                  <div className="title">Jingles</div>
                  <div className="param" id="0">
                    {localActiveSpiner.asset_id}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          <div className="blockRightTop">
            <div className="infoPapBlock">
              <div className="titlePap">PAP</div>
              <div className="titleDay">
                Usage
                <br />
                PerDay
              </div>
              <div
                id={`${
                  localActiveSpiner.vip == 0 || props.reallSpendCredit !== 0
                    ? ""
                    : props.manyLeftDays > 5
                    ? "activeBg"
                    : "activeBg2"
                }`}
                className="papDay"
              >
                {/* {localActiveSpiner.runes === 0 || localActiveSpiner.vip == 0 ? activeRuna.pap : activeRuna.papPlusRuna} */}
                {localActiveSpiner.vip == 0
                  ? "0"
                  : localActiveSpiner.runes === 0 ||
                    (specRunes &&
                      specRunes !== null &&
                      Date.now() > specRunes?.endtime * 1000)
                  ? activeRuna.pap
                  : activeRuna.papPlusRuna}
              </div>
            </div>
            <div
              className={
                localActiveSpiner.vip && props.reallSpendCredit == 0
                  ? "activ__ether"
                  : "noactiv__ether"
              }
              onMouseEnter={() => setShowVipInfo(true)}
              onMouseLeave={() => setShowVipInfo(false)}
            >
              <div
                className={
                  localActiveSpiner.vip && props.reallSpendCredit == 0
                    ? ""
                    : "ether"
                }
                onClick={() => {
                  props.actvipspinTrans(
                    score,
                    localActiveSpiner,
                    true,
                    spinSpeedBuffer,
                    allSumSpinsBuffer
                  );
                  handleClickAudio();
                }}
              ></div>
              <div className="close__ether"></div>
            </div>
            {/* #activeBg- подсветка зеленым когда больше 5 дней для према./ #activeBg2 - когда меньше либо равно 5 дням */}
            {/* Расчитывается по формуле. Текущий баланс/Использование в день= */}

            {localActiveSpiner.vip && props.reallSpendCredit == 0 ? (
              <div id="activeBg" className="block__ether">
                <div className="ether__ready"></div>
                <div
                  className="ether__start"
                  onClick={() => {
                    props.actvipspinTrans(
                      score,
                      localActiveSpiner,
                      !localActiveSpiner.vip,
                      spinSpeedBuffer,
                      allSumSpinsBuffer
                    );
                    handleClickAudio();
                  }}
                  onMouseEnter={() => setShowNoVipInfo(true)}
                  onMouseLeave={() => setShowNoVipInfo(false)}
                ></div>
              </div>
            ) : null}

            <div
              onClick={() => {
                props.confirmUnstake(true, localActiveSpiner.asset_id);
                handleClickAudio();
              }}
              className="close"
              onMouseEnter={() => setShowDeactivSpinInfo(true)}
              onMouseLeave={() => setShowDeactivSpinInfo(false)}
            ></div>
          </div>
        </div>

        <div className="card__player__blue">
          <div className="card__player">
            <div className="card__player__level">
              <div className="card__player__block">
                <button
                  onMouseEnter={() => setShowOneInfo(true)}
                  onMouseLeave={() => setShowOneInfo(false)}
                  className={`card__player__btn ${
                    spinSpeedBuffer === 1 ? "active" : ""
                  }`}
                  id="item"
                >
                  {" "}
                  <span className="SpinSpid">1</span>
                </button>
                <button
                  onMouseEnter={() => setShowTwoInfo(true)}
                  onMouseLeave={() => setShowTwoInfo(false)}
                  className={`card__player__btn ${
                    spinSpeedBuffer === 2 ? "active" : ""
                  }`}
                  id="item"
                >
                  {" "}
                  <span className="SpinSpid">2</span>
                </button>
                <button
                  onMouseEnter={() => setShowThreeInfo(true)}
                  onMouseLeave={() => setShowThreeInfo(false)}
                  className={`card__player__btn ${
                    spinSpeedBuffer === 3 ? "active" : ""
                  }`}
                  id="item"
                >
                  {" "}
                  <span className="SpinSpid">3</span>
                </button>
                <button
                  onMouseEnter={() => setShowForeInfo(true)}
                  onMouseLeave={() => setShowForeInfo(false)}
                  className={`card__player__btn ${
                    spinSpeedBuffer === 4 ? "active" : ""
                  }`}
                  id="item"
                >
                  {" "}
                  <span className="SpinSpid">4</span>
                </button>
                <button
                  onMouseEnter={() => setShowFiveInfo(true)}
                  onMouseLeave={() => setShowFiveInfo(false)}
                  className={`card__player__btn ${
                    spinSpeedBuffer === 5 ? "active" : ""
                  }`}
                  id="item"
                >
                  {" "}
                  <span className="SpinSpid">5</span>
                </button>
              </div>
            </div>
            <>
              <div
                className="progress__block "
                style={{ position: "relative" }}
                onMouseEnter={() => setShowSpeedInfo(true)}
                onMouseLeave={() => setShowSpeedInfo(false)}
              >
                <div className="progressbar" style={{ position: "relative" }}>
                  {/*  */}
                  {/* <span className="progressPercent">
                    {Math.round((allSumSpinsBuffer / maxClicks) * 100)}
                  </span> */}
                </div>
                {localActiveSpiner.speed === 5 ? (
                  <div className="progressSpeed">
                    <div className="speed">Speed</div>
                    <div id="speed-progress" className="speed progress">
                      Max
                    </div>
                  </div>
                ) : (
                  <div className="progressSpeed">
                    <div className="speed">Next speed</div>
                    <div id="speed-progress" className="speed progress">
                      {" "}
                      {allSumSpinsBuffer}/{maxClicks} Spins
                    </div>
                  </div>
                )}
                {/*  */}
                {localActiveSpiner.speed === 5 ? (
                  <div className="bgProgSpeed">
                    <div
                      id="progress-bar"
                      className="progress__fon"
                      style={{
                        height: "20",
                        width: `100`,
                        borderRadius: "3px",
                        background:
                          "var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%))",
                        transition: "width 0.5s",
                        position: "absolute",
                        top: "0px",
                        // left: '31px',
                        zIndex: 9999,
                      }}
                    ></div>
                  </div>
                ) : (
                  <div className="bgProgSpeed">
                    <div
                      id="progress-bar"
                      className="progress__fon"
                      style={{
                        height: "20",
                        width: `${(allSumSpinsBuffer / maxClicks) * 100}%`,
                        borderRadius: "3px",
                        background:
                          "var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%))",
                        transition: "width 0.5s",
                        position: "absolute",
                        top: "0px",
                        // left: '31px',
                        zIndex: 9999,
                      }}
                    ></div>
                  </div>
                )}
              </div>
            </>
          </div>

          <div className="info__block">
            <div className="info__block__left">
              <div
                className="first"
                onMouseEnter={() => setShowFirstInfo(true)}
                onMouseLeave={() => setShowFirstInfo(false)}
              >
                {localActiveSpiner.runes !== 0 &&
                localActiveSpiner.vip !== 0 ? (
                  <span>Generate</span>
                ) : (
                  <span>Consume</span>
                )}
                <span className="first consumption">
                  {consumption.toFixed(4)}
                </span>
              </div>
              <div
                className="second"
                onMouseEnter={() => setShowSecondInfo(true)}
                onMouseLeave={() => setShowSecondInfo(false)}
              >
                <span>Multiplier</span>

                {localActiveSpiner.rarity >= 0 && spinSpeedBuffer >= 0 && (
                  <span className="second multiplier">
                    {mult[localActiveSpiner.rarity][spinSpeedBuffer].toFixed(4)}
                  </span>
                )}
              </div>
            </div>
            <div
              className="info__block__right"
              onMouseEnter={() => setShowStorageInfo(true)}
              onMouseLeave={() => setShowStorageInfo(false)}
            >
              <span className="storage" id="stor" ref={storRef}>
                {score.toFixed(4)}
              </span>
              <div className="icon__fonStor"></div>
              <span
                className="storage__bottom"
                onMouseEnter={() => setShowStorageInfo(true)}
                onMouseLeave={() => setShowStorageInfo(false)}
              >
                SPN
              </span>{" "}
              <br />
              <span className="title__s">Storage</span>
              <br />
            </div>
          </div>
          {/* 
      
          {/* ------- */}
          {activeButtonSpin === 0 && (
            <React.Fragment>
              <div
                className={`commonBody__1 ${
                  timer || (vip && runa && props.reallSpendCredit == 0)
                    ? spinSpeedBuffer
                    : "stopsAnimation"
                }`}
              >
                {showCanvas === true ? (
                  <canvas ref={canvasRef} width="400" height="400" />
                ) : (
                  <>
                    {timer ||
                    (localActiveSpiner.runes != 0 &&
                      vip &&
                      props.reallSpendCredit == 0) ? (
                      <img src={iconPromoRotat} alt="Promo" />
                    ) : (
                      <img src={iconPromo} alt="Promo" />
                    )}
                  </>
                )}
              </div>
            </React.Fragment>
          )}
          {activeButtonSpin === 1 && (
            <React.Fragment>
              <div
                className={`commonBody__1 ${
                  timer || (vip && runa && props.reallSpendCredit == 0)
                    ? spinSpeedBuffer
                    : "stopsAnimation"
                }`}
              >
                {showCanvas === true && props.reallSpendCredit == 0 ? (
                  <canvas ref={canvasRef} width="400" height="400" />
                ) : (
                  <>
                    {timer ||
                    (localActiveSpiner.runes != 0 &&
                      vip &&
                      props.reallSpendCredit == 0) ? (
                      <img src={iconCommonRotat} alt="Common" />
                    ) : (
                      <img src={iconCommon} alt="Common" />
                    )}
                  </>
                )}
              </div>
            </React.Fragment>
          )}
          {activeButtonSpin === 2 && (
            <React.Fragment>
              <div
                className={`commonHead__1 ${
                  timer || (vip && runa && props.reallSpendCredit == 0)
                    ? spinSpeedBuffer
                    : "stopsAnimation"
                }`}
              >
                {showCanvas === true ? (
                  <canvas ref={canvasRef} width="400" height="400" />
                ) : (
                  <>
                    {timer ||
                    (localActiveSpiner.runes != 0 &&
                      vip &&
                      props.reallSpendCredit == 0) ? (
                      <img src={iconUncomonRotat} alt="uncomon" />
                    ) : (
                      <img src={iconUncomon} alt="uncomon" />
                    )}
                  </>
                )}
              </div>
            </React.Fragment>
          )}
          {activeButtonSpin === 3 && (
            <React.Fragment>
              <div
                className={`commonBody__1 ${
                  timer || (vip && runa && props.reallSpendCredit == 0)
                    ? spinSpeedBuffer
                    : "stopsAnimation"
                } `}
              >
                {showCanvas === true ? (
                  <canvas ref={canvasRef} width="400" height="400" />
                ) : (
                  <>
                    {timer ||
                    (localActiveSpiner.runes != 0 &&
                      vip &&
                      props.reallSpendCredit == 0) ? (
                      <img src={iconRareRotat} alt="rare" />
                    ) : (
                      <img src={iconRare} alt="rare" />
                    )}
                  </>
                )}
              </div>
            </React.Fragment>
          )}
          {activeButtonSpin === 4 && (
            <React.Fragment>
              <div
                className={`commonHead__1 ${
                  timer || (vip && runa && props.reallSpendCredit == 0)
                    ? spinSpeedBuffer
                    : "stopsAnimation"
                } `}
              >
                {showCanvas === true ? (
                  <canvas ref={canvasRef} width="400" height="400" />
                ) : (
                  <>
                    {timer ||
                    (localActiveSpiner.runes != 0 &&
                      vip &&
                      props.reallSpendCredit == 0) ? (
                      <img src={iconEpicRotat} alt="Epic" />
                    ) : (
                      <img src={iconEpic} alt="Epic" />
                    )}
                  </>
                )}
              </div>
            </React.Fragment>
          )}
          {activeButtonSpin === 5 && (
            <React.Fragment>
              <div
                className={`commonHead__1 ${
                  timer || (vip && runa && props.reallSpendCredit == 0)
                    ? spinSpeedBuffer
                    : "stopsAnimation"
                } `}
              >
                {showCanvas === true ? (
                  <canvas ref={canvasRef} width="400" height="400" />
                ) : (
                  <>
                    {timer ||
                    (localActiveSpiner.runes != 0 &&
                      vip &&
                      props.reallSpendCredit == 0) ? (
                      <img
                        className="iconSpinn"
                        src={iconLegendRotat}
                        alt="Legend"
                      />
                    ) : (
                      <img
                        className="iconSpinn"
                        src={iconLegend}
                        alt="Legend"
                      />
                    )}
                  </>
                )}
              </div>
            </React.Fragment>
          )}
          {activeButtonSpin === 6 && (
            <React.Fragment>
              <div
                className={`commonHead__1 ${
                  timer || (vip && runa && props.reallSpendCredit == 0)
                    ? spinSpeedBuffer
                    : "stopsAnimation"
                } `}
              >
                {showCanvas === true ? (
                  <canvas ref={canvasRef} width="400" height="400" />
                ) : (
                  <>
                    {timer ||
                    (localActiveSpiner.runes != 0 &&
                      vip &&
                      props.reallSpendCredit == 0) ? (
                      <img src={iconJingesRotat} alt="jingles" />
                    ) : (
                      <img src={iconJingles} alt="jingles" />
                    )}
                  </>
                )}
              </div>
            </React.Fragment>
          )}
          <div>
            <div>
              {localActiveSpiner.runes == 0 ? (
                <>
                  <div className="secondDiv">
                    <div className={buttons[activeButtonSpin].bgClass}></div>
                    <div
                      className={buttons[activeButtonSpin].btnClass}
                      onClick={() => {
                        props.openRunaModal("stake", true, localActiveSpiner);
                        handleClickAudio();
                      }}
                    ></div>
                  </div>
                </>
              ) : null}

              {localActiveSpiner.runes != 0 ? (
                <>
                  <div className="firstDiv" style={{ display: "block" }}>
                    {/* при сжигании руны скрыть) */}
                    {localActiveSpiner.runes != 111111111111111 ? (
                      <>
                        <div
                          id={activeRuna.stakeRuna}
                          className="runa open-btn"
                        >
                          <div
                            id={activeRuna.runaOpen}
                            className="item__spiner__runa"
                          ></div>
                        </div>
                        <div
                          onClick={() => {
                            props.openRunaModal(
                              "unstake",
                              true,
                              localActiveSpiner,
                              score
                            );
                            handleClickAudio();
                          }}
                          className="unstake__run"
                        ></div>
                      </>
                    ) : null}
                    {/*  */}
                    <div id={activeRuna.runaBg}>
                      {localActiveSpiner.runes == 111111111111111 ? (
                        <>
                          <div
                            style={
                              //leftSecondToNextLevel === 0 &&
                              Date.now() >
                              (specRunes &&
                                specRunes !== null &&
                                specRunes?.endtime * 1000)
                                ? { opacity: "0.5" }
                                : null
                            }
                            className={activeRuna.soul}
                          ></div>
                          <button
                            className="burnSpinney"
                            onClick={() => {
                              props.openRunaModal(
                                "stake1",
                                true,
                                localActiveSpiner,
                                score
                              );
                              handleClickAudio();
                            }}
                          ></button>
                          <button
                            className="soulClose"
                            onClick={() => {
                              props.openRunaModal(
                                "unstake2",
                                true,
                                localActiveSpiner,
                                score
                              );
                              handleClickAudio();
                            }}
                          ></button>
                          {
                            //leftSecondToNextLevel === 0 &&
                            Date.now() >
                            (specRunes &&
                              specRunes !== null &&
                              specRunes?.endtime * 1000) ? (
                              <div id="activeBg2" className="expired">
                                Expired
                              </div>
                            ) : null
                          }
                        </>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div>
              <div className="fon__left">
                <div
                  id={isActiveSpin ? activeRuna.className : ""}
                  className="item__spiner"
                ></div>

                <div className="spin__spn">
                  <p>{activeRuna.value}</p>
                  {/* <p>SPN</p> */}
                </div>
              </div>

              {activeButtonSpin >= 0 && activeButtonSpin <= 6 && (
                <React.Fragment>
                  <div
                    onClick={() => {
                      props.openModalMarket("open", localActiveSpiner, score);
                      handleClickAudio();
                    }}
                    className="fon__right__button"
                  >
                    <span className="SpinSpid">Mini Market</span>
                  </div>
                </React.Fragment>
              )}
            </div>

            <div>
              <div className="fon__right">
                <div
                  id={isActiveSpin ? activeRuna.classNameLeft : ""}
                  className="item__spiner__runa"
                ></div>

                <div className="runa__spn">
                  <p>{activeRuna.valueLeft}</p>
                  {/* <p>SPN</p> */}
                </div>
              </div>

              {activeButtonSpin >= 0 && activeButtonSpin <= 6 && (
                <React.Fragment>
                  <div
                    onClick={() => {
                      props.openModalMarket("open", localActiveSpiner, score);
                      handleClickAudio();
                    }}
                    className="fon__left__button"
                  >
                    <span className="SpinSpid">Mini Market</span>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          {/*  */}
          <div className="bottom__menu">
            {timer ? (
              <button className="StopClaimDis">
                {" "}
                <span className="tokenDiscont"> Stop & claim</span>
              </button>
            ) : (
              <button className="btnStop">
                {" "}
                <span
                  className="SpinSpid"
                  onClick={() => {
                    props.confirmClaim(3, localActiveSpiner, score);
                    handleClickAudio();
                  }}
                >
                  {" "}
                  Stop & claim
                </span>
              </button>
            )}
            {(localActiveSpiner.runes !== 0 &&
              localActiveSpiner.runes != 111111111111111 &&
              localActiveSpiner.vip !== 0) ||
            Date.now() <
              (specRunes && specRunes !== null && specRunes?.endtime * 1000) ? (
              <button disabled className="TimeStartBtn">
                <Countdown
                  daysInHours
                  date={Date.now() + leftSecondToNextLevel * 1000}
                  onComplete={(e) => {
                    setTimer(false);
                  }}
                  renderer={({ days, hours, minutes, seconds, completed }) => {
                    if (completed) {
                      return <span>00:00:00</span>;
                    } else {
                      return (
                        <>
                          {specRunes ? days + ":" : ""}
                          {formatTime(hours)}:{formatTime(minutes)}:
                          {formatTime(seconds)}
                        </>
                      );
                    }
                  }}
                />
              </button>
            ) : localActiveSpiner.runes !== 0 && localActiveSpiner.vip === 0 ? (
              <button
                id={activeRuna.color}
                spinButtonRef={spinButtonRef}
                className={`btn ${timer ? "TimeStartBtn" : ""}`}
                ref={spinButtonRef}
                onClick={() => {
                  props.confirmClaim(16, localActiveSpiner);
                  handleClickAudio();
                }}
              >
                {timer ? (
                  <Countdown
                    daysInHours
                    date={localActiveSpiner.timer * 1000}
                    onComplete={(e) => {
                      setTimer(false);
                    }}
                  />
                ) : (
                  "Spin"
                )}
              </button>
            ) : (
              <button
                id={activeRuna.color}
                spinButtonRef={spinButtonRef}
                className={`btn ${timer ? "TimeStartBtn" : ""}`}
                ref={spinButtonRef}
                onClick={() =>
                  spinTrans(
                    localActiveSpiner.asset_id,
                    totalRewardState,
                    localActiveSpiner.countdown
                  )
                }
              >
                {timer ? (
                  <Countdown
                    daysInHours
                    date={localActiveSpiner.timer * 1000}
                    onComplete={(e) => {
                      setTimer(false);
                    }}
                  />
                ) : (
                  "Spin"
                )}
              </button>
            )}
          </div>
        </div>
      </div>
      {/* {
        localActiveSpiner.runes !== 0 && localActiveSpiner.vip !== 0 || timer ?
          null
          :
          <div className="winterFonBtn"></div>
      } */}
    </div>
  );
};

export default UniversalSpin;
