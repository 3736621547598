
import React, { useState, useEffect, useMemo, useContext } from "react";
import '../Drop/Drop.css';
import Countdown from 'react-countdown';
import DropSingle from "./DropSingle";
import { Route, Routes } from 'react-router-dom';
import useGetMyRefferal from '../../components/Wax/useGetMyRefferal';
import useGetAllReferralsVip from '../../components/Wax/useGetAllReferralsVip';
import useGetAllReferralsVipYou from '../../components/Wax/useGetAllReferralsVipYou';
import useGetMyPackWl from '../../components/Wax/useGetMyPackWl';
import useGetMyPackWlNo from '../../components/Wax/useGetMyPackWlNo';
import useGetAllPackInfo from '../../components/Wax/useGetAllPackInfo';
import { buyPack } from '../../components/Wax/Transactions';
import { UALContext } from 'ual-reactjs-renderer';
import { ToastContainer, toast } from 'react-toastify';



const Drop = ({ userInGameState }) => {
    const notify = (e) => toast(e);
    const ual = useContext(UALContext);

    const [referralVipData, setReferralVipData] = useState(null);
    const [referralVipDataYou, setReferralVipDataYou] = useState(null);
    const [headuser, setHeaduser] = useState(null);
    const [headuserYou, setHeaduserYou] = useState(null);
    //const [myReferralGetLocal, setMyReferralGetLocal] = useState(null);
    const [myPackWlGetLocal, setMyPackWlGetLocal] = useState(null);
    const [myPackWlNoGetLocal, setMyPackWlNoGetLocal] = useState(null);
    const [allPackInfoGetLocal, setAllPackInfoGetLocal] = useState(0);
    const [refresh, setRefresh] = useState(null);

    const { myReferralGet } = useGetMyRefferal(userInGameState?.user);
    const { allReferralsVipGet } = useGetAllReferralsVip(headuser); // вызываем хук на верхнем уровне
    const { allReferralsVipYouGet } = useGetAllReferralsVipYou(userInGameState?.user); // вызываем хук на верхнем уровне
    const { myPackWlGet } = useGetMyPackWl(userInGameState?.user);
    const { myPackWlNoGet } = useGetMyPackWlNo(userInGameState?.user);
    const { allPackInfoGet } = useGetAllPackInfo(userInGameState?.user, refresh);


    const updateTimer = (e) => {
        setRefresh(prevKey => prevKey + 1);
    };

    //console.log("userInGameState?.useruserInGameState?.user", userInGameState?.user);
    //console.log("allPackInfoGet", allPackInfoGet);
    useEffect(() => {
        setAllPackInfoGetLocal(allPackInfoGet);
        //console.log("allPackInfoGet", allPackInfoGet);
    }, [allPackInfoGet, refresh]);

    //console.log("allPackInfoGetLocal", allPackInfoGetLocal);

    useEffect(() => {
        setMyPackWlGetLocal(myPackWlGet);
    }, [myPackWlGet, refresh]);

    useEffect(() => {
        setMyPackWlNoGetLocal(myPackWlNoGet);
    }, [myPackWlNoGet, refresh]);

    useEffect(() => {
        if (myReferralGet?.headuser && myReferralGet.headuser !== headuser) {
            setHeaduser(myReferralGet.headuser);
        }
    }, [myReferralGet, refresh]);

    useEffect(() => {
        if (allReferralsVipGet && JSON.stringify(allReferralsVipGet) !== JSON.stringify(referralVipData)) {
            setReferralVipData(allReferralsVipGet);
        }
    }, [allReferralsVipGet, refresh]);

    useEffect(() => {
        if (allReferralsVipYouGet && JSON.stringify(allReferralsVipYouGet) !== JSON.stringify(referralVipDataYou)) {
            setReferralVipDataYou(allReferralsVipYouGet);
        }
    }, [allReferralsVipYouGet, refresh]);

    const buyPackTrans = async (quant, type, count) => {
        try {
            const { activeUser } = ual;
            const { accountName } = activeUser;
            const memo = 'buypack ' + type + " " + count + " ";
            const detalPrice = parseFloat(quant / 100000000).toFixed(8) + " WAX"
            const transactionPayload = buyPack(accountName, detalPrice, memo);

            const transaction = await activeUser.signTransaction(transactionPayload, {
                blocksBehind: 3,
                expireSeconds: 30,
            });

            let indexItem = 0;
            let nowl = false;
            if (type == "starter") {
                indexItem = 0;
                nowl = true;
            } else if (type == "mystery") {
                indexItem = 1;
            } else if (type == "infinity") {
                indexItem = 2;
            }

            let max_buy;
            let alreadyBuy;
            let updateUserWl = JSON.parse(JSON.stringify(myPackWlGet));;
            if (myPackWlGetLocal && myPackWlGetLocal.user !== undefined && nowl === false) {
                //max_buy = myPackWlGet.pass_number[0] + (indexItem != 2 ? myPackWlGet.pass_number[1] : 0);

                if (indexItem == 1) {
                    max_buy = myPackWlGet.pass_number[0];
                } else if (indexItem == 2) {
                    max_buy = myPackWlGet.pass_number[1];
                }

                alreadyBuy = myPackWlGet.buy[indexItem];

                if (max_buy == alreadyBuy + count) {
                    updateUserWl.cooldown[indexItem] = Math.floor(Date.now() / 1000) + (20 * 60);
                    updateUserWl.buy[indexItem] = 0;
                } else {
                    updateUserWl.buy[indexItem] += count;
                }
                setMyPackWlGetLocal(updateUserWl);
            } else if (myPackWlNoGetLocal === undefined || myPackWlNoGetLocal && myPackWlNoGetLocal?.cooldown <= Math.floor(Date.now() / 1000)) {
                if (myPackWlNoGetLocal === undefined) {
                    setMyPackWlNoGetLocal({
                        user: userInGameState?.user,
                        cooldown: Math.floor(Date.now() / 1000) + (20 * 60)
                    });
                } else {
                    setMyPackWlNoGetLocal(prevState => ({
                        ...prevState,
                        cooldown: Math.floor(Date.now() / 1000) + (20 * 60)
                    }));
                }
            }

            let updatedAllPack = [...allPackInfoGetLocal];
            //for (let index = 0; index < count; index++) {
            let lottIndex = allPackInfoGetLocal.findIndex(pack => pack.packtype == type);
            if (lottIndex !== -1) {
                updatedAllPack[lottIndex].count -= count;
            }
            //}
            setAllPackInfoGetLocal(updatedAllPack);

            console.log("transactionPayload", transactionPayload);

            notify("Transaction ID: " + transaction?.transactionId);
        } catch (e) {
            toast(e.toString(), {
                progressClassName: 'error-progress',
            });
            console.log(e);
            // if (e instanceof RpcError)
            //   console.log(JSON.stringify(e.json, null, 2));
        }
    }



    const drops = [
        {
            rar: "fonStarter",
            packtype: "starter",
            title: "StarterTitle",
            contains: "X2 Spinney",
            pass: "all",
            bg: "starter",
            wl: false,
            pack: "StarterPackIcon",
            dropChance: [
                {

                    type: "Common",
                    perc: 40
                },
                {
                    type: "Uncommon",
                    perc: 30
                },
                {

                    type: "Rare",
                    perc: 20
                },
                {
                    type: "Epic",
                    perc: 10
                },
                {

                    type: "Legend",
                    perc: 0
                },
            ],
            info: {
                supply: 1000,
                sold: 895,
                available: 105
            },
            price: 650,

        },
        {
            rar: "fonMystery",
            packtype: "mystery",
            title: "MysteryTitle",
            contains: "X3 Spinney",
            pack: "MysteryBoxIcon",
            pass: "all",
            bg: "mistery",
            wl: true,
            dropChance: [
                {
                    type: "Common",
                    perc: 20
                },
                {
                    type: "Uncommon",
                    perc: 35
                },
                {
                    type: "Rare",
                    perc: 30
                },
                {
                    type: "Epic",
                    perc: 12
                },
                {
                    type: "Legend",
                    perc: 3
                },
            ],
            info: {
                supply: 1000,
                sold: 895,
                available: 105,
            },
            price: 900,

        },
        {
            rar: "fonInfinity",
            packtype: "infinity",
            title: "InfinityTitle",
            contains: "X2 Spinney",
            contains2: "X1 Runes",
            pack: "InfinityBoxIcon",
            pass: "elite",
            bg: "infinix",
            wl: true,
            dropChance: [
                {
                    type: "Common",
                    perc: 20
                },
                {
                    type: "Uncommon",
                    perc: 35
                },
                {
                    type: "Rare",
                    perc: 30
                },
                {
                    type: "Epic",
                    perc: 12
                },
                {
                    type: "Legend",
                    perc: 3
                },
            ],
            info: {
                supply: 1000,
                sold: 895,
                available: 105,
            },
            price: 1300,

        }
    ]

    return (
        <>
            <div className="Drop">
                {allPackInfoGetLocal && drops.map((item, index) => {
                    return (
                        <DropSingle item={item} allReferralsVipGet={allReferralsVipGet} allReferralsVipYouGet={allReferralsVipYouGet} buyPackTrans={buyPackTrans} myPackWlGet={myPackWlGetLocal} myPackWlNoGetLocal={myPackWlNoGetLocal} allPackInfoGetLocal={allPackInfoGetLocal} index={index} updateTimer={updateTimer} />
                    )
                })
                }
            </div>
        </>
    )
}


export default Drop;