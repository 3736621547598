import React, { useContext } from 'react';
import '../OfferTag/OfferTag.css'
import { useState, useEffect } from 'react';
import '../BoostUp/BoostUp.css';
import { regLottOffer } from '../../../components/Wax/Transactions';
import { UALContext } from 'ual-reactjs-renderer';
import { ToastContainer, toast } from 'react-toastify';

import useLotteryOfferList from '../../../components/Wax/useLotteryOfferList';
import useLotteryOfferListWinners from '../../../components/Wax/useLotteryOfferListWinners';
import OfferTagDetalInfo from './OfferTagDetalInfo';


import ModalTicket from '../../Modal/ModalTicket';
import ModalWinner from '../../Modal/ModalWinner';

import { Link } from 'react-router-dom';

const ModalRegisterTickets = ({ active, setActive, children, claimAsset, claimTrans, allResInGameOffer, regLottOfferTrans, id }) => {
  const [isActive, setIsActive] = useState(active);
  const [value, setValue] = useState(1);


  const handleChange = (e) => {
    const newValue = e.target.value;
    if (newValue === "") {
      setValue("0");
    } else if (!isNaN(newValue) && parseInt(newValue, 10) >= 0) {
      setValue(newValue);
    }
  };

  const increment = () => {
    setValue((prevValue) => {
      if (prevValue === "") {
        return "1";
      } else {
        return (parseInt(prevValue, 10) + 1);
      }
    });
  };

  const decrement = () => {
    setValue((prevValue) => {
      if (prevValue === "" || prevValue <= 1) {
        return "1";
      } else {
        return (parseInt(prevValue, 10) - 1);
      }
    });
  };

  const OnClose = () => {
    setActive(false);
  }




  return (<div id="ModalTicket" className={active ? "modal__content activerun" : "modal__content"} >
    <div id="modal17" >
      {children}
      <div className="WinPageFon  LosePage">
        <div className="WinPage ">

          <div className="titleWinner"></div>
          <div className={`container__game ${active ? "modal activerun" : "modal"}`}>
            <div className="title titlBoostUp">
              <div className="icon__reg__ticket"></div>
              <span>Register your tickets</span>
              <div className="numberTic">
                <p>Tickets available:</p>
                <div className="container__ticket">
                  <div className="icon__smoll__tic"></div>
                  <div className="fon__tic"><p className='nambTicket'>{allResInGameOffer && allResInGameOffer.res.length && allResInGameOffer.res[0]}</p></div>
                </div>
              </div>
              <div className="parag__modalRuna">How many tickets do you want to <br /> register for this offer?</div>

              <div className="box__slots">
                <span onClick={() => decrement()} className='minus'>-</span>
                <input
                  type="text"
                  className="total__add"
                  value={value}
                  onChange={handleChange}
                />
                <span onClick={() => increment()} className='plus'>+</span>
              </div>
            </div>

            <div className="btn__modal">
              <button onClick={OnClose} >Cancel</button>
              <button onClick={() => regLottOfferTrans(id, value)} >Confirm</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  )
}


const OfferTag = (props) => {
  const notify = (e) => toast(e);

  const ual = useContext(UALContext);

  const [activeBlock, setActiveBlock] = useState(null);
  const [selectedBlockActive, setSelectedBlockActive] = useState(null);
  const [idActive, setIdActive] = useState(null);
  const [idWinner, setIdWinner] = useState(null);
  const [modal17Active, setModal17Active] = useState(false);
  const [activeLott, setActiveLott] = useState(false);
  const [allLotteryOfferListGetLocal, setAllLotteryOfferListGetLocal] = useState(null);
  const [allLotteryOfferListWinnersGetLocal, setAllLotteryOfferListWinnersGetLocal] = useState(null);
  const [allResInGameOffer, setAllResInGameOffer] = useState(false);

  const [refreshKey1, setRefreshKey1] = useState(0);

  const { allLotteryOfferListGet } = useLotteryOfferList(props?.userInGameState?.user);
  const { allLotteryOfferListWinnersGet } = useLotteryOfferListWinners(props?.userInGameState?.user, refreshKey1);

  useEffect(() => {
    //console.log("allLotteryOfferListGet", allLotteryOfferListGet);
    if (allLotteryOfferListGet && allLotteryOfferListGet.length) {
      //console.log("allLotteryOfferListGet[0].id", allLotteryOfferListGet[0].tmpl_prise);
      setAllLotteryOfferListGetLocal(allLotteryOfferListGet);
      setSelectedBlockActive("active-lottery");
      setIdActive(0);
    }

  }, [allLotteryOfferListGet]);

  useEffect(() => {
    setAllLotteryOfferListWinnersGetLocal(allLotteryOfferListWinnersGet);
  }, [allLotteryOfferListWinnersGet]);

  useEffect(() => {
    setAllResInGameOffer(props.allResInGameLocal);
  }, [props.allResInGameLocal]);


  const openModalRegisterTickets = (type, id) => {
    if (type === "open") {
      setModal17Active(true);
      setActiveLott(id);
    }
  };

  const handleClickActive = (type, index) => {
    setIdWinner(null);
    setSelectedBlockActive(type);
    setIdActive(index);
  };

  // для розыгрыша
  // левая таблица
  // icon: icon__epic / icon__legend / icon__rare / icon__comonca / icon__uncomon 
  // правая таблица|
  // cardChangeIcon: rar__4/viol__3/sunsh__5/leaf__3

  // классы для картинок левой таблицы 
  // .icon__ticketlotery,
  // .icon__legendrun,
  // .icon__violetrun,
  // .icon__rarerun,
  // .icon__uncomonrun,
  // .icon__commonrun,
  // .icon__magmus,



  const chooseDate = (tmpl) => {

    //console.log("tmpl", tmpl)
    //console.log("process.env.REACT_APP_SPINNEY_COMMON", process.env.REACT_APP_SPINNEY_COMMON)
    switch (tmpl) {
      case parseInt(process.env.REACT_APP_SPINNEY_COMMON):
        return {
          id: 0,
          tmpl: process.env.REACT_APP_SPINNEY_COMMON,
          cardChangeBurnClass: 'common',
          cardChangeClass: 'leaf__3',
          cardChangeIcon: 'commonca__2',
          width: '3%',
          icon: 'icon__comonca',
          title: 'Win a Steelade NFT',
          regPrice: '99999',
          MyChance: '10%',
          myRegistrations: '99',
          craftPrice: '9999',
          stateText: '31.08.2023',
          speedProgress: '9999/9999',
          state: 'Raffled',
          background: 'linear-gradient(180deg, #A3A7B6 0%, #4E556B 100%)',
          colorCircle: 'common',

        }
        break;
      case parseInt(process.env.REACT_APP_SPINNEY_UNCOMMON):
        return {
          id: 1,
          tmpl: process.env.REACT_APP_SPINNEY_UNCOMMON,
          cardChangeBurnClass: 'Uncommon__bg',
          cardChangeClass: 'leaf__3',
          cardChangeIcon: 'leaf__3',
          width: '15%',
          icon: 'icon__uncomon',
          title: 'Win a Leaflynn NFT',
          regPrice: '99999',
          MyChance: '10%',
          myRegistrations: '99',
          craftPrice: '9999',
          stateText: '31.08.2023',
          speedProgress: '9999/9999',
          state: 'Raffled',
          background: 'linear-gradient(180deg, #A4D685 0%, #538E2E 100%)',
          colorCircle: 'uncommon',

        }
        break;
      case parseInt(process.env.REACT_APP_SPINNEY_RARE):
        return {
          id: 2,
          tmpl: process.env.REACT_APP_SPINNEY_RARE,
          cardChangeBurnClass: 'rare__3',
          cardChangeClass: 'rare__4',
          cardChangeIcon: 'rar__4',
          width: '3%',
          icon: 'icon__rare',
          title: 'Win a Frostee NFT',
          regPrice: '9999',
          MyChance: '15%',
          myRegistrations: '98',
          craftPrice: '9998',
          stateText: '3 winners',
          speedProgress: '7999/9999',
          state: 'Registration is open',
          background: 'linear-gradient(180deg, #69CDFF 0%, #2E7CF5 100%)',
          colorCircle: 'frosty',

        }
        break;
      case parseInt(process.env.REACT_APP_SPINNEY_EPIC):
        return {
          id: 3,
          tmpl: process.env.REACT_APP_SPINNEY_EPIC,
          cardChangeBurnClass: 'epic__4',
          cardChangeClass: 'viol__3',
          cardChangeIcon: 'viol__3',
          width: '70%',
          icon: 'icon__epic',
          title: 'Win a Violetta NFT',
          regPrice: '99999',
          MyChance: '10%',
          myRegistrations: '99',
          craftPrice: '9999',
          stateText: '3 winners',
          speedProgress: '7999/9999',
          state: 'Registration is open',
          background: 'linear-gradient(180deg, #BE76E4 0%, #6D4AAC 100%)',
          colorCircle: 'epic__4',
        }
        break;
      case parseInt(process.env.REACT_APP_SPINNEY_LEGEND):
        return {
          id: 4,
          tmpl: process.env.REACT_APP_SPINNEY_LEGEND,
          cardChangeBurnClass: 'legend__5',
          cardChangeClass: 'sunsh__5',
          cardChangeIcon: 'sunsh__5',
          width: '100%',
          icon: 'icon__legend',
          title: 'Win a Sunshine NFT',
          regPrice: '9999',
          MyChance: '15%',
          myRegistrations: '98',
          craftPrice: '9998',
          stateText: '3 winners',
          speedProgress: '9999/9999',
          state: 'Registration is open',
          background: 'linear-gradient(180deg, #F08019 0%, #F6CD3A 100%)',
          colorCircle: 'sunshine',
        }
        break;
      case parseInt(process.env.REACT_APP_BASIC_PASS):
        return {
          id: 5,
          tmpl: process.env.REACT_APP_BASIC_PASS,
          cardChangeBurnClass: 'basic__player',
          cardChangeClass: 'basic__player',
          cardChangeIcon: 'silver__one__image',
          width: '100%',
          icon: 'basic__player',
          title: 'Win a Basic Premium Access',
          regPrice: '9999',
          MyChance: '50%',
          myRegistrations: '98',
          craftPrice: '9998',
          stateText: '3 winners',
          speedProgress: '9999/9999',
          state: 'Registration is open',
          background: 'linear-gradient(180deg, #F08019 0%, #F6CD3A 100%)',
          colorCircle: 'colorPassProgress',
        }
        break;
      case parseInt(process.env.REACT_APP_ELIT_PASS):
        return {
          id: 6,
          tmpl: process.env.REACT_APP_ELIT_PASS,
          cardChangeBurnClass: 'gold__player',
          cardChangeClass: 'gold__player',
          cardChangeIcon: 'gold__one__image',
          width: '100%',
          icon: 'gold__player',
          title: 'Win a Elite Premium Access',
          regPrice: '9999',
          MyChance: '50%',
          myRegistrations: '98',
          craftPrice: '9998',
          stateText: '3 winners',
          speedProgress: '9999/9999',
          state: 'Registration is open',
          background: 'linear-gradient(180deg, #F08019 0%, #F6CD3A 100%)',
          colorCircle: 'colorPassProgress',
        }
        break;
      case parseInt(process.env.REACT_APP_SUPREME_PASS):
        return {
          id: 7,
          tmpl: process.env.REACT_APP_SUPREME_PASS,
          cardChangeBurnClass: 'supreme__player',
          cardChangeClass: 'supreme__player',
          cardChangeIcon: 'supreme__one__image',
          width: '100%',
          icon: 'supreme__player',
          title: 'Win a Supreme Premium Access',
          regPrice: '9999',
          MyChance: '50%',
          myRegistrations: '98',
          craftPrice: '9998',
          stateText: '3 winners',
          speedProgress: '9999/9999',
          state: 'Registration is open',
          background: 'var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%))',
          colorCircle: 'colorPassProgress',
        }
        break;
      case parseInt(process.env.REACT_APP_BOX_STARTER):
        return {
          id: 8,
          tmpl: process.env.REACT_APP_BOX_STARTER,
          cardChangeBurnClass: 'icon__starter',
          cardChangeClass: 'icon__starter',
          cardChangeIcon: 'starter__one__image',
          width: '100%',
          icon: 'icon__starter',
          title: 'Win a Starter Box',
          regPrice: '9999',
          MyChance: '50%',
          myRegistrations: '98',
          craftPrice: '9998',
          stateText: '3 winners',
          speedProgress: '9999/9999',
          state: 'Raffled',
          background: 'var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%))',
          colorCircle: 'colorPassProgress',
          cardNan: 'OfferTacCircl'
        }
        break;
      case parseInt(process.env.REACT_APP_BOX_MYSTERY):
        return {
          id: 9,
          tmpl: process.env.REACT_APP_BOX_MYSTERY,
          cardChangeBurnClass: 'icon__mystery',
          cardChangeClass: 'icon__mystery',
          cardChangeIcon: 'mystery__one__image',
          width: '100%',
          icon: 'icon__mystery',
          title: 'Win a Mystery Box',
          regPrice: '9999',
          MyChance: '50%',
          myRegistrations: '98',
          craftPrice: '9998',
          stateText: '3 winners',
          speedProgress: '9999/9999',
          state: 'Raffled',
          background: 'var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%))',
          colorCircle: 'colorPassProgress',
          cardNan: 'OfferTacCircl'
        }
        break;
      case parseInt(process.env.REACT_APP_BOX_INFINITY):
        return {
          id: 10,
          tmpl: process.env.REACT_APP_BOX_INFINITY,
          cardChangeBurnClass: 'icon__infinity',
          cardChangeClass: 'icon__infinity',
          cardChangeIcon: 'infinity__one__image',
          width: '100%',
          icon: 'icon__infinity',
          title: 'Win a Infinity Box',
          regPrice: '9999',
          MyChance: '50%',
          myRegistrations: '98',
          craftPrice: '9998',
          stateText: '3 winners',
          speedProgress: '9999/9999',
          state: 'Raffled',
          background: 'var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%))',
          colorCircle: 'colorPassProgress',
          cardNan: 'OfferTacCircl'
        }
        break;

      default:
        break;
    }
  }

  const formatDate = (time) => {
    const now = new Date(time * 1000);
    return now.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  }

  const regLottOfferTrans = async (id, number) => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;

      const transactionPayload = regLottOffer(accountName, id, number);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      props.resDisp(false, number);

      let updatedAllLott = [...allLotteryOfferListGetLocal];
      for (let index = 0; index < number; index++) {
        let lottIndex = allLotteryOfferListGetLocal.findIndex(lott => lott.id == id);
        if (lottIndex !== -1) {
          updatedAllLott[lottIndex].userlist.push(props.userInGameState.user);
        }
      }
      setAllLotteryOfferListGetLocal(updatedAllLott);

      setModal17Active(false);

      notify("Transaction ID: " + transaction?.transactionId);

      let count = 0;


      const intervalId = setInterval(() => {
        console.log("asdads");
        if (count < 10) {
          refreshAssets();
          count++;
        } else {
          clearInterval(intervalId);
        }
      }, 1000);

    } catch (e) {
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });
      console.log(e);
    }
  }

  let lopLottDetal;

  const [currentItem, setCurrentItem] = useState(null);
  const [modal15Active, setModal15Active] = useState(false);
  const [modal16Active, setModal16Active] = useState(false);
  const [activePrise, setActivePrise] = useState(null);

  const refreshAssets = (type) => {
    setRefreshKey1(prevKey => prevKey + 1);
  };

  useEffect(() => {
    const storedDate = localStorage.getItem("latestDateOfferWinnerList");
    const itemsToShow = [];

    console.log("allLotteryOfferListWinnersGetLocal", allLotteryOfferListWinnersGetLocal);
    // Сортируем массив по времени (от наибольшего к наименьшему)
    if (allLotteryOfferListWinnersGetLocal !== null) {
      allLotteryOfferListWinnersGetLocal.sort((a, b) => a.time - b.time);

      for (const item of allLotteryOfferListWinnersGetLocal) {
        if (!storedDate || item.time > parseInt(storedDate, 10)) {
          console.log("item", item);
          if (item.user === props?.userInGameState?.user || item.userlist.includes(props?.userInGameState?.user)) {
            itemsToShow.push(item);
            // Обновляем значение в localStorage здесь
            localStorage.setItem("latestDateOfferWinnerList", item.time.toString());
          }
        }
      }

      console.log("itemsToShow", itemsToShow)

      setCurrentItem(itemsToShow);

      if (itemsToShow.length) {
        if (itemsToShow[0].users.includes(props?.userInGameState?.user)) {
          console.log("aaaaaaa");
          openModalWinner("open");
          setActivePrise(itemsToShow[0]);
        }/* else {
          openModalTicket("open");
        }*/
      }
    }

  }, [allLotteryOfferListWinnersGetLocal]);

  useEffect(() => {
    if (currentItem && currentItem.length) {
      setTimeout(() => {
        if (currentItem[0].users.includes(props?.userInGameState?.user)) {
          openModalWinner("open");
          setActivePrise(currentItem[0]);
        } else {
          openModalWinner("close");
        }
        localStorage.setItem("latestDateOfferWinnerList", currentItem[0].time.toString());
      }, 1000);
    }
  }, [currentItem]);

  // const openModalTicket = (type) => {
  //   if (type === "open") {
  //     setModal15Active(true);
  //   } else {
  //     setModal15Active(false);
  //     const remainingItems = [...currentItem];
  //     remainingItems.shift();
  //     setCurrentItem(remainingItems);
  //   }
  // };

  const openModalWinner = (type) => {
    if (type === "open") {
      setModal16Active(true);
    } else {
      setModal16Active(false);
      const remainingItems = [...currentItem];
      //console.log("remainingItems", remainingItems);
      remainingItems.shift();
      //console.log("remainingItems2", remainingItems);
      setCurrentItem(remainingItems);
    }
  };

  return (
    <>
      <div className="OfferTag__content tickets">
        <div className="fon__bg__slider"></div>
        <div className="fonBgDarck"></div>
        <div className="sliderOfferTag">
          <div className="OfferTag__block" style={selectedBlockActive === chooseDate.length ? { background: '' } : {}}>
            <div className="offer_card">
              <p>
                Choose the Offer that entices you and proceed to register your Tickets. <br /> Once the required number of registered Tickets has been reached, the winners will be randomly selected from registered Tickets.
              </p>
              <div className="BlockMyAvailable"><div className="MyAvailable">Available tickets:</div>
                <div className="fonTicketHeader">
                  <div className="icon__smoll__tic"></div>
                  <div className="nambTicket">{allResInGameOffer && allResInGameOffer.user != undefined ? allResInGameOffer.res[0] : 0}</div>
                </div>
                {/* <div className="reg__t">{allResInGameOffer && allResInGameOffer.user != undefined ? allResInGameOffer.res[0] : 0}</div> */}
              </div>
            </div>

            {allLotteryOfferListGetLocal && allLotteryOfferListGetLocal.map((data, index) => {

              //console.log("allLotteryOfferListGetLocal", allLotteryOfferListGetLocal);
              console.log("data", data)
              let info = chooseDate(data.tmpl_prise)
              let allcount = 0;
              let myreg = 0;
              //console.log("selectedBlockActive", selectedBlockActive);
              //console.log("allLotteryBoostListGet.userlist", data.userlist);
              if (data && data.userlist !== undefined && data.userlist.length) {
                myreg = data?.userlist.filter(value => value === props.userInGameState.user).length;
                //console.log("myreg", myreg);
                allcount = data?.userlist.length;
              }
              return (
                // selectedBlock === info.tmpl && data.id !== undefined && data.id == id || selectedBlock === info.tmpl && data.time !== undefined && data.time == id
                <button
                  key={index}
                  className={`second__card ${idActive === index && selectedBlockActive === "active-lottery" ? 'clicked' : ''}`}
                  onClick={() => handleClickActive("active-lottery", index)}
                >
                  <div className="info__card__uncommon">
                    <div className="infoProgressRegist">
                      <div className="BlockProgressInfo">
                        {console.log("info", info)}
                        <button className={`circtbtn ${info.colorCircle}`}>
                        </button>
                        <span>Registration is open</span>
                      </div>
                      {/* progress-bar */}
                      <div className="progressFonOfferTag">
                        <div
                          id="progress-bar"
                          className="progress__fon"
                          style={{
                            height: '6',
                            width: `${100 / data.max_number * allcount}%`,
                            borderRadius: '3px',
                            background: `${info.background}`,
                            transition: 'width 0.5s',
                          }}
                        ></div>
                      </div>
                      <div className="progressSpeed">
                        <div className="speed">Total registered</div>
                        <div id="speed-progress" className="speed progress">
                          {allcount}/{data.max_number}
                        </div>
                      </div>
                    </div>
                    <div className="BlockMyRegist"><div className="MyRegist">My registrations</div><div className="reg__t">{myreg}</div></div>
                    <div className="BlockMyChance"><div className="MyChance">MyChance</div><div className="reg__t">{(100 / data.max_number * myreg).toFixed(1)}%</div></div>
                  </div>
                  <div className={info.icon} ></div>
                  <button className='name__state'>
                    {formatDate(data.time)}
                  </button>
                </button>

              )
            })
            }

            {allLotteryOfferListWinnersGetLocal && allLotteryOfferListWinnersGetLocal.map((data, index) => {
              let info = chooseDate(data.tmpl_prise)
              let allcount = 0;
              let myreg = 0;
              //console.log("selectedBlockWinner", selectedBlockWinner);
              //console.log("allLotteryBoostListGet.userlist", data.userlist);
              if (data && data.userlist !== undefined && data.userlist.length) {
                myreg = data?.userlist.filter(value => value === props.userInGameState.user).length;
                //console.log("myreg", myreg);
                allcount = data?.userlist.length;
              }
              return (
                <button
                  key={index}
                  className={`second__card ${idActive === index && selectedBlockActive === "winner-list" ? 'clicked' : ''}`}
                  onClick={() => handleClickActive("winner-list", index)}
                >
                  <div className="info__card__uncommon">
                    <div className="infoProgressRegist Reffled">
                      <div className="BlockProgressInfo">
                        <button className={`circtbtn ${info.colorCircle}`}></button>
                        <span>Raffled</span>
                      </div>
                      <Link to={`/winnerslistoffer`} className="DropBuy">Winners</Link>

                    </div>
                  </div>
                  <div className={info.icon} ></div>
                  <button className='name__state'>
                    {data.users.length === 1 ? "1 winner" : data.users.length + " winners"}
                  </button>
                </button>

              )
            })
            }

          </div>
        </div>

        {selectedBlockActive === "active-lottery" ?
          allLotteryOfferListGetLocal && allLotteryOfferListGetLocal.map((data, index) => (
            <OfferTagDetalInfo
              data={data}
              id={idActive}
              userInGameState={props.userInGameState}
              chooseDate={chooseDate}
              selectedBlock={selectedBlockActive}
              openModalRegisterTickets={openModalRegisterTickets}
              index={index}
            />
          ))
          : selectedBlockActive === "winner-list" ?
            allLotteryOfferListWinnersGetLocal && allLotteryOfferListWinnersGetLocal.map((data, index) => (

              < OfferTagDetalInfo
                data={data}
                id={idActive}
                userInGameState={props.userInGameState}
                chooseDate={chooseDate}
                selectedBlock={selectedBlockActive}
                openModalRegisterTickets={openModalRegisterTickets}
                index={index}
              />
            ))
            : null
        }

      </div>

      {modal17Active ? (
        <ModalRegisterTickets
          active={modal17Active}
          setActive={setModal17Active}
          allResInGameOffer={allResInGameOffer}
          resDisp={props.resDisp}
          userInGameState={props.userInGameState}
          regLottOfferTrans={regLottOfferTrans}
          id={activeLott}
        ></ModalRegisterTickets>
      ) : null}

      {modal16Active ? (
        <ModalWinner
          active={modal16Active}
          setActive={setModal16Active}
          openModalWinner={openModalWinner}
          activePrise={activePrise}
          type="offer"
          user={props?.userInGameState?.user}
        ></ModalWinner>
      ) : null}

    </>

  )
}

export default OfferTag;
