import React, { useContext, useState, useEffect } from 'react';
import '../Craft/Craft.css';
import { Route, Routes } from 'react-router-dom';

import { UALContext } from 'ual-reactjs-renderer';
import { ToastContainer, toast } from 'react-toastify';
import { itemBuy } from '../../components/Wax/Transactions';
import useGetSpnWax from '../../components/Wax/useGetSpnWax';

const Craft = ({ craftTrans, balanceDisp, userInGameState }) => {

    console.log("userInGameState", userInGameState);

    const notify = (e) => toast(e);
    const ual = useContext(UALContext);

    const [spnCurr, setSpnCurr] = useState(null);
    const [refreshKey4, setRefreshKey4] = useState(0);
    const [waxprice, setWaxPrice] = useState(0);
    let { tacoCurr } = useGetSpnWax(userInGameState.user, refreshKey4);

    const refreshAssets = (type) => {
        setRefreshKey4(prevKey => prevKey + 1);
    };

    useEffect(() => {
        if (tacoCurr && tacoCurr.id !== undefined) {
            setSpnCurr(tacoCurr);

            let spn = parseInt(tacoCurr.pool1.quantity.replace(/\D/g, '')) * 10000;
            let wax = parseInt(tacoCurr.pool2.quantity.replace(/\D/g, ''));

            function truncateToDecimalPlace(num, decimalPlaces) {
                const power = Math.pow(10, decimalPlaces);
                const truncatedNum = Math.floor(num * power) / power;
                return truncatedNum.toFixed(decimalPlaces);
            }

            let cut = truncateToDecimalPlace(wax / spn, 8);

            setWaxPrice(wax / spn);
            // console.log("spn", spn);
            // console.log("wax", wax);
            // console.log("wax / spn", (wax / spn));
        }
    }, [tacoCurr]);

    const buyItemTrans = async (quant, item) => {
        try {
            const { activeUser } = ual;
            const { accountName } = activeUser;
            //const detalPrice = parseFloat(quant).toFixed(4) + " " + process.env.REACT_APP_VAL

            //let wax = (parseFloat(quant / 2) * waxprice).toFixed(8);

            function truncateToDecimalPlace(num, decimalPlaces) {
                const power = Math.pow(10, decimalPlaces);
                const truncatedNum = Math.floor(num * power) / power;
                return truncatedNum.toFixed(decimalPlaces);
            }

            let wax = truncateToDecimalPlace(parseFloat(quant / 2) * waxprice, 8);

            //let waxwax = (parseFloat(quant / 2) * waxprice).toString().slice(0, 10);

            // console.log("wax", wax)
            // console.log("waxwax", waxwax)
            // console.log("quant / 2) * waxprice", (quant / 2) * waxprice);
            // console.log("parseFloat(quant / 2) * waxprice", parseFloat(quant / 2) * waxprice)

            // console.log("parseFloat(quant / 2)", parseFloat(quant / 2) * waxprice)
            // console.log("waxprice", waxprice)
            // console.log("waxwax", wax)
            // console.log("quant", parseInt(quant))

            const transactionPayload = itemBuy(accountName, item.toLowerCase(), wax, parseFloat(quant / 2));

            console.log("transactionPayload", transactionPayload);

            console.log("quant", quant);
            const transaction = await activeUser.signTransaction(transactionPayload, {
                blocksBehind: 3,
                expireSeconds: 30,
            });

            //console.log(transaction);
            // setAccountBalanceState(prevState => (
            //     (parseFloat(prevState) + parseFloat(quant * ((100 - fee) / 100))).toFixed(4)
            // ));
            balanceDisp(false, quant);
            // setUserInGame(prevState => ({
            //   ...prevState,
            //   balance: prevState.balance - quant * 10000
            // }));

            notify("Transaction ID: " + transaction?.transactionId);
        } catch (e) {
            toast(e.toString(), {
                progressClassName: 'error-progress',
            });
            console.log(e);
            // if (e instanceof RpcError)
            //   console.log(JSON.stringify(e.json, null, 2));
        }
    }

    const [activeMenu, setActiveMenu] = useState("packs");
    const [activeCard, setActiveCard] = useState(0);
    const [activeCards, setActiveCards] = useState(0);

    const handleMenuClick = (menu) => {
        setActiveMenu(menu);
        setActiveCard(0);
        setActiveCards(0);
    };

    const handleCardClick = (index) => {
        setActiveCard(index);
        setActiveCards(index);
    };
    const cardData = [

        // {
        //     type: 'mag',
        //     rarity: 'Promo',
        //     name: 'Magmus',
        //     consume: '0,2',
        //     cooldown: '3',
        //     image: 'mag',
        //     multipliers: [
        //         { speed: '1Speed', value: '1,1' },
        //         { speed: '2Speed', value: '1,325' },
        //         { speed: '3Speed', value: '1,55' },
        //         { speed: '4Speed', value: '1,775' },
        //         { speed: '5Speed', value: '2' }
        //     ],
        //     price: '96.0000'
        // },
        {
            type: 'steel',
            rarity: 'Common',
            name: 'Steelade',
            consume: '1',
            cooldown: '3',
            image: 'steel',
            multipliers: [
                { speed: '1Speed', value: '1,1' },
                { speed: '2Speed', value: '1,325' },
                { speed: '3Speed', value: '1,55' },
                { speed: '4Speed', value: '1,775' },
                { speed: '5Speed', value: '2' }
            ],
            price: process.env.REACT_APP_DEF_PRICE * (1 + (1 - process.env.REACT_APP_DEF)) * 1.2
        },
        {
            type: 'leaf',
            rarity: 'Uncommon',
            name: 'Leaflynn',
            consume: '2',
            cooldown: '3',
            image: 'leaf',
            multipliers: [
                { speed: '1Speed', value: '1,1' },
                { speed: '2Speed', value: '1,35' },
                { speed: '3Speed', value: '1,6' },
                { speed: '4Speed', value: '1,85' },
                { speed: '5Speed', value: '2,1' }
            ],
            price: process.env.REACT_APP_DEF_PRICE * 2 * (1 + (1 - process.env.REACT_APP_DEF)) * 1.2
        },
        {
            type: 'frost',
            rarity: 'Rare',
            name: 'Frostee',
            consume: '8',
            cooldown: '6',
            image: 'frost',
            multipliers: [
                { speed: '1Speed', value: '1,1' },
                { speed: '2Speed', value: '1,375' },
                { speed: '3Speed', value: '1,65' },
                { speed: '4Speed', value: '1,925' },
                { speed: '5Speed', value: '2,2' }
            ],
            price: process.env.REACT_APP_DEF_PRICE * 4 * (1 + (1 - process.env.REACT_APP_DEF)) * 1.2
        },
        {
            type: 'violetta',
            rarity: 'Epic',
            name: 'Violetta',
            consume: '16',
            cooldown: '6',
            image: 'violetta',
            multipliers: [
                { speed: '1Speed', value: '1,1' },
                { speed: '2Speed', value: '1,4' },
                { speed: '3Speed', value: '1,7' },
                { speed: '4Speed', value: '2' },
                { speed: '5Speed', value: '2,3' }
            ],
            price: process.env.REACT_APP_DEF_PRICE * 8 * (1 + (1 - process.env.REACT_APP_DEF)) * 1.2
        },
        {
            type: 'sunshine',
            rarity: 'Legend',
            name: 'Sunshine',
            consume: '64',
            cooldown: '12',
            image: 'sunshine',
            multipliers: [
                { speed: '1Speed', value: '1,1' },
                { speed: '2Speed', value: '1,45' },
                { speed: '3Speed', value: '1,8' },
                { speed: '4Speed', value: '2,15' },
                { speed: '5Speed', value: '2,5' }
            ],
            price: process.env.REACT_APP_DEF_PRICE * 16 * (1 + (1 - process.env.REACT_APP_DEF)) * 1.2
        },
    ];

    const renderCard = (card, index) => {
        return (
            <>
                <div key={index} className={`top__menu__name ${card.rarity}`}>
                    <div className="player">{card.rarity}</div>
                    <div className="title">{card.name}</div>
                </div>

                <div className={`${card.type}__one__info cfon`}>

                    <div className='leaf__one__block'>
                        <div className={`${card.image}__one__image`}></div>
                        <div className='leaf__one__description'>
                            <div className="inf__leaf__c">Consume: {card.consume}</div>
                            <div className="inf__leaf__c">Cooldown: {card.cooldown}</div>
                            <div className="multi_leaf">Multipliers:</div>
                            {card.multipliers.map((multiplier) => (
                                <div id="inf__num">
                                    <div className="inf__num">{multiplier.speed}</div>
                                    <div className="">{multiplier.value}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="Craft__price">
                        <div className="name__wallet price"><p>Price</p></div>
                        <div className="block__sum">
                            <div className="icon__sum"><p>{Math.ceil(card.price)}</p></div>
                            <div className="icon__spn__p"><p>SPN</p></div>
                            <div className="icon__modal">
                                <div className="price__icon"></div>
                            </div>
                        </div>
                    </div>
                    <button className='leaf__one__button' onClick={() => craftTrans(card.price, "craftspinney", "buy")}>Craft</button>
                </div>

            </>
        );
    };
    const runaData = [
        {
            type: 'starter',
            rarity: 'Starter',
            name: 'Starter Box',
            images: 'starter',
            price: process.env.REACT_APP_DEF_PRICE * 5.2 * (1 + (1 - process.env.REACT_APP_DEF)) * 1.3,
            ext: 'boxExt',
        },
        {
            type: 'mystery',
            rarity: 'Mystery',
            name: 'Mystery Box',
            images: 'mystery',
            price: process.env.REACT_APP_DEF_PRICE * 10.62 * (1 + (1 - process.env.REACT_APP_DEF)) * 1.3,
            ext: 'boxExt',
        },
        {
            type: 'infinity',
            rarity: 'Infinity',
            name: 'Infinity Box',
            images: 'infinity',
            price: process.env.REACT_APP_DEF_PRICE * 14.16 * (1 + (1 - process.env.REACT_APP_DEF)) * 1.3,
            ext: 'boxExt',
        }
        // {
        //     type: 'mag',
        //     rarity: 'Promo',
        //     name: 'Magmus',
        //     consume: '0,2',
        //     images: 'promo',
        //     multipliers: [
        //         { speed: '1Speed', value: '1,333' },
        //         { speed: '2Speed', value: '1,3' },
        //         { speed: '3Speed', value: '1,3' },
        //         { speed: '4Speed', value: '1,3' },
        //         { speed: '5Speed', value: '1,3' }
        //     ],
        //     price: '192.0000'
        // },
        // {
        //     type: 'steel',
        //     rarity: 'Common',
        //     name: 'The Infinity Rune',
        //     consume: '1',
        //     images: 'common',
        //     multipliers: [
        //         { speed: '1Speed', value: '1,333' },
        //         { speed: '2Speed', value: '1,3' },
        //         { speed: '3Speed', value: '1,3' },
        //         { speed: '4Speed', value: '1,3' },
        //         { speed: '5Speed', value: '1,3' }
        //     ],
        //     price: '1150.0000'
        // },
        // {
        //     type: 'leaf',
        //     rarity: 'Uncommon',
        //     name: 'The Infinity Rune',
        //     consume: '2',
        //     images: 'uncommon',
        //     multipliers: [
        //         { speed: '1Speed', value: '1,333' },
        //         { speed: '2Speed', value: '1,3' },
        //         { speed: '3Speed', value: '1,3' },
        //         { speed: '4Speed', value: '1,3' },
        //         { speed: '5Speed', value: '1,3' }
        //     ],
        //     price: '2300.0000'
        // },
        // {
        //     type: 'frost',
        //     rarity: 'Rare',
        //     name: 'The Infinity Rune',
        //     consume: '8',
        //     images: 'rare',
        //     multipliers: [
        //         { speed: '1Speed', value: '1,333' },
        //         { speed: '2Speed', value: '1,3' },
        //         { speed: '3Speed', value: '1,3' },
        //         { speed: '4Speed', value: '1,3' },
        //         { speed: '5Speed', value: '1,3' }
        //     ],
        //     price: '4600.0000'
        // },
        // {
        //     type: 'violetta',
        //     rarity: 'Epic',
        //     name: 'The Infinity Rune',
        //     consume: '14',
        //     images: 'epic',
        //     multipliers: [
        //         { speed: '1Speed', value: '1,333' },
        //         { speed: '2Speed', value: '1,3' },
        //         { speed: '3Speed', value: '1,3' },
        //         { speed: '4Speed', value: '1,3' },
        //         { speed: '5Speed', value: '1,3' }
        //     ],
        //     price: '9200.0000'
        // },

        // {
        //     type: 'sunshine',
        //     rarity: 'Legend',
        //     name: 'The Infinity Rune',
        //     consume: '64',
        //     images: 'legend',
        //     multipliers: [
        //         { speed: '1Speed', value: '1,333' },
        //         { speed: '2Speed', value: '1,3' },
        //         { speed: '3Speed', value: '1,3' },
        //         { speed: '4Speed', value: '1,3' },
        //         { speed: '5Speed', value: '1,3' }
        //     ],
        //     price: '18430.0000'
        // },

    ];


    const renderRuna = (runa, index) => {
        return (
            <>
                <div key={index} className={`top__menu__name ${runa.rarity} ${runa.ext}`}>
                    <div className="player">{runa.rarity}</div>
                    <div className="title">{runa.name}</div>
                </div>
                <div id='Boxes' className={`${runa.type}__one__infob cfon`}>

                    <div className='leaf__one__block'>
                        <div className={`${runa.images}__one__image`}></div>
                        {/* <div className='leaf__one__description'>
                        <div className="inf__leaf__c">Consume: {runa.consume}</div>
                        <div className="multi_leaf">Multipliers:</div>
                        {runa.multipliers.map((multiplier) => (
                            <div id="inf__num">
                                <div className="inf__num">{multiplier.speed}</div>
                                <div className="">{multiplier.value}</div>
                            </div>
                        ))}
                    </div> */}
                    </div>
                    <div className="Craft__price">
                        <div className="name__wallet price"><p>Price</p></div>
                        <div className="block__sum">
                            <div className="icon__sum"><p>{Math.ceil(runa.price)}</p></div>
                            <div className="icon__spn__p"><p>SPN</p></div>
                            <div className="icon__modal">
                                <div className="price__icon"></div>
                            </div>
                        </div>
                    </div>
                    <button className='leaf__one__button' onClick={() => craftTrans(runa.price, "craftboxes")}>Craft</button>
                </div>
            </>
        );
    };
    const packData = [
        {
            type: 'silver',
            rarity: 'Basic',
            name: 'Premium Access',
            consume: '4',
            images: 'silver__player',
            priceWax: process.env.REACT_APP_PRICE_PASS_BASIC,
            priceSPN: process.env.REACT_APP_PRICE_PASS_BASIC
        },
        {
            type: 'gold',
            rarity: 'Elite',
            name: 'Premium Access',
            images: 'gold__player',
            priceWax: process.env.REACT_APP_PRICE_PASS_ELIT,
            priceSPN: process.env.REACT_APP_PRICE_PASS_ELIT
        },
        {
            type: 'supreme',
            rarity: 'Supreme',
            name: 'Premium Access',
            images: 'supreme',
            priceWax: process.env.REACT_APP_PRICE_PASS_SUPREME,
            priceSPN: process.env.REACT_APP_PRICE_PASS_SUPREME
        }
    ];
    const renderPack = (pack, index, activeMenu) => {
        return (
            <>
                <div key={index} className={`top__menu__name ${pack.rarity}`}>
                    <div className="player">{pack.rarity}</div>
                    <div className="title">{pack.name}</div>
                </div>
                <div id='Pass' className={`${pack.type}__one__info cfon`}>

                    <div className='leaf__one__block'>
                        <div className={`${pack.type}__one__image`}></div>

                    </div>
                    <div className="Craft__price premPass">
                        <div className="name__wallet price"><p>Price</p></div>
                        <div className="block__sum">
                            {/* <div className="icon__sum"><p>{pack.price}</p></div> */}
                            <div className="">
                                <div className="block__sum SpmSummToken">
                                    <div className="leftSumm">
                                        <div className="icon__sum "><p className='contSpn'>{Math.ceil(pack.priceSPN / 2)}</p></div>
                                        <div className="icon__spn__p icon__sum__spn"><p>SPN</p></div>
                                    </div>

                                    <div className="spnWax name__wallet price"><span>&</span></div>

                                    <div className="rightSumm">
                                        <div className="icon__sum ">
                                            <p className='contWax'>
                                                {((parseFloat(pack.priceSPN / 2) * waxprice).toFixed(2)).toString().slice(0, 10)}
                                            </p>
                                        </div>
                                        <div className="icon__spn__p icon__sum__wax"><p>WAX</p></div>
                                    </div>
                                </div>
                            </div>

                            <div className="icon__modal">
                                <div className="price__icon"></div>
                            </div>
                        </div>
                    </div>
                    {activeMenu === "packs" ?
                        <button className='leaf__one__button' onClick={() => buyItemTrans(pack.priceSPN, pack.rarity)}>Craft</button>
                        :
                        <button className='leaf__one__button' onClick={() => craftTrans(pack.priceSPN, "craftconsumables")}>Craft</button>
                    }
                </div>
            </>
        );
    };



    return (
        <div id='craft'>
            <div className="craft">
                <div className="craft__left__content">
                    <div className="craft__menu">
                        <button
                            // disabled style={{ opacity: 0.5, cursor: 'not-allowed' }}
                            className={activeMenu === "packs" ? "activeBtn" : ""}
                            onClick={() => handleMenuClick("packs")}
                        >
                            Consumable
                        </button>
                        <button
                            className={activeMenu === "spinney" ? "activeBtn" : ""}
                            onClick={() => handleMenuClick("spinney")}
                        >
                            Spinney
                        </button>
                        <button
                            // disabled style={{ opacity: 0.5, cursor: 'not-allowed' }}
                            className={activeMenu === "runes" ? "activeBtn" : ""}
                            onClick={() => handleMenuClick("runes")}
                        >
                            Boxes
                        </button>
                    </div>
                    {activeMenu === "packs" && (
                        <div className="craft__cards">
                            <div className="craft__card__slider">
                                <div className="packs">
                                    <button id='available__steel__1'
                                        // disabled style={{ opacity: 0.5, cursor: 'not-allowed' }}
                                        className={activeCards === 0 ? "active" : ""}
                                        onClick={() => handleCardClick(0)}>
                                        <div className="silver__player runes__pic">
                                        </div>
                                    </button>
                                    <button id='available__leaf__2'
                                        // disabled style={{ opacity: 0.5, cursor: 'not-allowed' }}
                                        className={activeCards === 1 ? "active" : ""}
                                        onClick={() => handleCardClick(1)}>
                                        <div className="gold__player runes__pic">
                                        </div>
                                    </button>
                                    <button id='available__leaf__2'
                                        className={activeCards === 2 ? "active" : ""}
                                        onClick={() => handleCardClick(2)}>
                                        <div className="supreme__player runes__pic">
                                        </div>
                                    </button>


                                </div>
                            </div>
                        </div>
                    )}
                    {activeMenu === "spinney" && (
                        <div className="craft__cards">
                            <div className="craft__card__slider">
                                <div className="spinney">
                                    {/* 
                                    <button id='available__mag__0'
                                        className={activeCard === 1 ? "active" : ""}
                                        onClick={() => handleCardClick(1)}>
                                        <div className="mag__player runes__pic">
                                        </div>
                                    </button> */}
                                    <button id='available__steel__1'
                                        className={activeCard === 0 ? "active" : ""}
                                        onClick={() => handleCardClick(0)}>
                                        <div className="steel__player runes__pic">
                                        </div>
                                    </button>
                                    <button id='available__leaf__2'
                                        className={activeCard === 1 ? "active" : ""}
                                        onClick={() => handleCardClick(1)}>
                                        <div className="leaf__player runes__pic">
                                        </div>
                                    </button>
                                    <button id='available__frost__3'
                                        className={activeCard === 2 ? "active" : ""}
                                        onClick={() => handleCardClick(2)}>
                                        <div className="frost__player runes__pic">
                                        </div>
                                    </button>

                                    <button id='available__violetta__4'
                                        className={activeCard === 3 ? "active" : ""}
                                        onClick={() => handleCardClick(3)}>
                                        <div className="violetta__player runes__pic">
                                        </div>
                                    </button>
                                    <button id='available__sunshine__5'
                                        className={activeCard === 4 ? "active" : ""}
                                        onClick={() => handleCardClick(4)}>
                                        <div className="sunshine__player runes__pic">
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {activeMenu === "runes" && (
                        <div className="craft__cards">
                            <div className="craft__card__slider">
                                <div className="runes">
                                    {/* <button id='available__promo__0'
                                        className={activeCard === 0 ? "active" : ""}
                                        onClick={() => handleCardClick(0)}>
                                        <div className="promo_player runes__pic">
                                        </div>
                                    </button> */}
                                    {/* <button id='available__common__1'
                                        className={activeCard === 0 ? "active" : ""}
                                        onClick={() => handleCardClick(0)}>
                                        <div className="common__player runes__pic">
                                        </div>
                                    </button>
                                    <button id='available__uncommon__2'
                                        className={activeCard === 1 ? "active" : ""}
                                        onClick={() => handleCardClick(1)}>
                                        <div className="uncommon__player runes__pic">
                                        </div>
                                    </button>
                                    <button id='available__rare__3'
                                        className={activeCard === 2 ? "active" : ""}
                                        onClick={() => handleCardClick(2)}>
                                        <div className="rare__player runes__pic">
                                        </div>
                                    </button>
                                    <button id='available__epic__4'
                                        className={activeCard === 3 ? "active" : ""}
                                        onClick={() => handleCardClick(3)}>
                                        <div className="epic__player runes__pic">
                                        </div>
                                    </button>

                                    <button id='available__legend__5'
                                        className={activeCard === 4 ? "active" : ""}
                                        onClick={() => handleCardClick(4)}>
                                        <div className="legend__player runes__pic">
                                        </div>
                                    </button> */}
                                    <button id='available__common__1'
                                        className={activeCard === 0 ? "active" : ""}
                                        onClick={() => handleCardClick(0)}>
                                        <div className="starter__player runes__pic">
                                        </div>
                                    </button>
                                    <button id='available__uncommon__2'
                                        className={activeCard === 1 ? "active" : ""}
                                        onClick={() => handleCardClick(1)}>
                                        <div className="mystery__player runes__pic">
                                        </div>
                                    </button>
                                    <button id='available__rare__3'
                                        className={activeCard === 2 ? "active" : ""}
                                        onClick={() => handleCardClick(2)}>
                                        <div className="infinity__player runes__pic">
                                        </div>
                                    </button>

                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="craft__right__content">
                    {activeMenu === "spinney" && (
                        <div className='bgFonCraft'>
                            {cardData.map((card, index) => (
                                activeCard === index && renderCard(card, index, activeMenu)
                            ))}
                        </div>
                    )}
                    {activeMenu === "runes" && (
                        <div className='bgFonCraft'>
                            {runaData.map((runa, index) => (
                                activeCard === index && renderRuna(runa, index, activeMenu)
                            ))}
                        </div>
                    )}
                    {activeMenu === "packs" && (
                        <div className='bgFonCraft'>
                            {packData.map((pack, index) => (
                                activeCards === index && renderPack(pack, index, activeMenu)
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>

    );
};

export default Craft;