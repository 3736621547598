import React, { useContext, useState, useEffect, useRef } from "react";
import "./BurnSalePage.scss";
import { transferToTheGame } from "./../Wax/Transactions";
import { UALContext } from "ual-reactjs-renderer";
import { ToastContainer, toast } from "react-toastify";
import ModalPartners from "../Modal/ModalPartners";
import OneBurn from "./OneBurn";
import useGetUserCollectionInfo from "./../Wax/useGetUserCollectionInfo";
import useGetUserCanBuySpn from "../../components/Wax/useGetUserCanBuySpn";
import useGetBurtCountList from "../../components/Wax/useGetBurtCountList";

const swichOtherCol = () => {};

const BurnSalePage = ({ userInGameState, allSpinner }) => {
  const ual = useContext(UALContext);
  const notify = (e) => toast(e);

  const [modal25Active, setModal25Active] = useState(false);
  //const [infoUser, setInfoUser] = useState({});
  const [allNftModal, setAllNftModal] = useState({});
  const [actTmpl, setActTmpl] = useState("");
  const [actIndex, setActIndex] = useState("");
  const [allUserCanBuySpnLocal, setAllUserCanBuySpnLocal] = useState([0, 0, 0]);
  const [localBurnList, setLocalBurnList] = useState([]);

  // const { userColInfo } = useGetUserCollectionInfo(userInGameState?.user);
  // useEffect(() => {
  //   if (
  //     userColInfo &&
  //     userColInfo.user !== undefined &&
  //     infoUser.user === undefined
  //   ) {
  //     setInfoUser(userColInfo);
  //   }
  // }, [userColInfo]);

  useEffect(() => {
    if (allSpinner && allSpinner.consumables !== undefined) {
      setAllNftModal(allSpinner.consumables);
    }
  }, [allSpinner]);

  const { allUserCanBuySpn } = useGetUserCanBuySpn(userInGameState?.user);
  useEffect(() => {
    if (allUserCanBuySpn && allUserCanBuySpn.canbuy !== undefined) {
      setAllUserCanBuySpnLocal(allUserCanBuySpn.canbuy);
      console.log("allUserCanBuySpnLocal", allUserCanBuySpn.canbuy);
    }
  }, [allUserCanBuySpn]);

  let { allBurtCountList } = useGetBurtCountList(userInGameState?.user);

  useEffect(() => {
    if (allBurtCountList && allBurtCountList.length) {
      console.log("allBurtCountList", allBurtCountList);
      setLocalBurnList(allBurtCountList);
    }
  }, [allBurtCountList]);

  const listSale = [
    {
      name: "Starter",
      tmpl: process.env.REACT_APP_BOX_STARTER,
      available: 150,
      price: 600,
      pass: process.env.REACT_APP_BASIC_PASS,
    },
    {
      name: "Mystery",
      tmpl: process.env.REACT_APP_BOX_MYSTERY,
      available: 150,
      price: 1200,
      pass: process.env.REACT_APP_ELIT_PASS,
    },
    {
      name: "infinity",
      tmpl: process.env.REACT_APP_BOX_INFINITY,
      available: 150,
      price: 1500,
      pass: process.env.REACT_APP_SUPREME_PASS,
    },
  ];

  const openModalPartners = (type, tmpl, index) => {
    if (type === "open") {
      setModal25Active(true);
      setActTmpl(tmpl);
      setActIndex(index);
    } else {
      setActTmpl("");
    }
  };

  const transferNftToTheGame = async (choosesAssetOnWallet) => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;
      const transactionPayload = transferToTheGame(
        accountName,
        choosesAssetOnWallet,
        "burnsale "
      );
      console.log("transactionPayload", transactionPayload);

      if (activeUser) {
        //
        // let updatedAssetsFromAtomic = [...assetsFromAtomic];
        // let indexNft = assetsFromAtomic.findIndex(item => item.asset_id == id);
        // updatedAssetsFromAtomic.splice(indexNft, 1);
        // setAssetsFromAtomic(updatedAssetsFromAtomic);
        const transaction = await activeUser.signTransaction(
          transactionPayload,
          {
            blocksBehind: 3,
            expireSeconds: 30,
          }
        );
        setModal25Active(false);

        let updatedAssetsInModal = [...allNftModal];
        // console.log("updatedAssetsInModal", updatedAssetsInModal);
        for (let index = 0; index < choosesAssetOnWallet.length; index++) {
          let asset = allNftModal.find(
            (spinner) => spinner.asset_id == choosesAssetOnWallet[index]
          );
          let spinnerIndex = allNftModal.findIndex(
            (spinner) => spinner.asset_id == choosesAssetOnWallet[index]
          );
          //console.log("spinnerIndex", spinnerIndex);
          if (spinnerIndex !== -1) {
            updatedAssetsInModal.splice(spinnerIndex, 1);
          }
        }

        let updateLocalBurnList = [...localBurnList];
        let specIndex = localBurnList.findIndex((item) => item.tmpl == actTmpl);
        if (specIndex !== -1) {
          const updatedBox = {
            ...updateLocalBurnList[specIndex],
            count:
              updateLocalBurnList[specIndex].count -
              choosesAssetOnWallet.length,
          };
          updateLocalBurnList[specIndex] = updatedBox;
        }
        setLocalBurnList(updateLocalBurnList);

        // console.log("actIndex", actIndex);
        // console.log("allUserCanBuySpnLocal", allUserCanBuySpnLocal);
        let UpdInfoUser = [...allUserCanBuySpnLocal];
        UpdInfoUser[actIndex] += choosesAssetOnWallet.length;
        setAllUserCanBuySpnLocal(UpdInfoUser);

        setAllNftModal(updatedAssetsInModal);
        //setAssetsFromAtomic(updatedAssetsFromAtomic);
        console.log("updatedAssetsInModal2", updatedAssetsInModal);

        notify("Transaction ID: " + transaction?.transactionId);
      }
    } catch (e) {
      toast(e.toString(), {
        progressClassName: "error-progress",
      });
      console.log(e);
    }
  };

  console.log("allUserCanBuySpnLocal", allUserCanBuySpnLocal);

  return (
    <>
      <div className="burnsale box-size">
        <div className="new-content">
          <div className="top-burn">
            Burn Premium Access to get a guaranteed <br />
            box claim on the first wave of the sale.
          </div>
          <div className="content-wrap">
            <div className="burn-group">
              {listSale && localBurnList && localBurnList.length
                ? listSale.map((item, index) => {
                    return (
                      <OneBurn
                        key={index}
                        item={item}
                        index={index}
                        openModalPartners={openModalPartners}
                        allUserCanBuySpnLocal={allUserCanBuySpnLocal}
                        oneListSale={localBurnList[index]}
                      />
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
      {modal25Active ? (
        <ModalPartners
          active={modal25Active}
          setActive={setModal25Active}
          openModalPartners={openModalPartners}
          transferNftToTheGame={transferNftToTheGame}
          userInGameState={userInGameState}
          allNftModal={allNftModal}
          swichOtherCol={swichOtherCol}
          typeModal={"burns"}
          actTmpl={actTmpl}
        ></ModalPartners>
      ) : null}
    </>
  );
};

export default BurnSalePage;
