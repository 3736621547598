
import React, { useState, useEffect } from "react";
import '../Drop/Drop.css';
import Countdown from 'react-countdown';
import { Route, Routes } from 'react-router-dom';

// sold__out-display:none; 

const DropSingle = ({
    item, // инфа пака на фронте
    buyPackTrans, // транза покупки пака
    allReferralsVipGet, // вип ли твой реферал
    allReferralsVipYouGet,
    myPackWlGet, // В вайт листе ли этот юзер, если да то тут инфа про пасы
    myPackWlNoGetLocal,
    allPackInfoGetLocal, // инфа про пак с контракта
    index,
    updateTimer
}) => {

    const [timer, setTimer] = useState(false);
    const [value, setValue] = useState(1);
    const [maxBuy, setMaxBuy] = useState(0);
    const [alreadyBuy, setAlreadyBuy] = useState(0);
    const [youCanBuy, setYouCanBuy] = useState(1);

    const handleChange = (e) => {
        // Проверка, является ли введенное значение числом или пустой строкой
        const newValue = e.target.value;
        if (!isNaN(newValue) || newValue === "") {
            setValue(newValue);
        }
    };


    const increment = () => {
        setValue((prevValue) => {
            if (prevValue === "") {
                return "1"
            } else if (prevValue = youCanBuy) {
                return youCanBuy
            }
            return (parseInt(prevValue, 10) + 1).toString(); // Иначе прибавим 1
        });
    };

    // const decrement = () => {
    //     setValue((prevValue) => {
    //         if (prevValue === "") return "-1"; // Если поле было пустым, начнем с -1
    //         return (parseInt(prevValue, 10) - 1).toString(); // Иначе уменьшим на 1
    //     });
    // };

    const decrement = () => {
        setValue((prevValue) => {
            if (prevValue === "") {
                console.log("111111111")
                return "-1"
            } else if (prevValue > youCanBuy) {
                console.log("222222")
                return youCanBuy
            }
            console.log("333333")
            const newValue = parseInt(prevValue, 10) - 1; // Вычисляем новое значение
            return (newValue < 0) ? "0" : newValue.toString(); // Проверяем и устанавливаем новое значение или 0, если оно меньше 0
        });
    };

    const [manyPackNormal, setManyPackNormal] = useState(0);
    const [manyPackPro, setManyPackPro] = useState(0);
    const [activeTypePack, setActiveTypePack] = useState(null);
    const [avail, setAvail] = useState(null);

    useEffect(() => {
        if (myPackWlGet && myPackWlGet.user !== undefined) {
            setManyPackNormal(myPackWlGet.pass_number[0]);
            setManyPackPro(myPackWlGet.pass_number[1]);



            let indexItem = 0;
            if (item.packtype == "starter") {
                indexItem = 0;
            } else if (item.packtype == "mystery") {
                indexItem = 1;
            } else if (item.packtype == "infinity") {
                indexItem = 2;
            }

            let localmaxBuy = 0;
            if (indexItem == 1) {
                localmaxBuy = myPackWlGet?.pass_number[0];
            } else if (indexItem == 2) {
                localmaxBuy = myPackWlGet?.pass_number[1];
            }

            setMaxBuy(localmaxBuy);
            setAlreadyBuy(myPackWlGet?.buy[indexItem]);
            setYouCanBuy(localmaxBuy - myPackWlGet?.buy[indexItem]);
        }
    }, [myPackWlGet]);

    const itemBlockchain = allPackInfoGetLocal.find(pack => pack.packtype === item.packtype);

    useEffect(() => {
        //console.log("22222222222222222222222222222222222222222222")
        if (itemBlockchain && itemBlockchain.packtype !== undefined) {
            if (myPackWlGet && myPackWlGet.user !== undefined) {
                if (itemBlockchain.packtype === "starter") {
                    setActiveTypePack(0);
                    setAvail((myPackWlGet.pass_number[0] + myPackWlGet.pass_number[1]) - myPackWlGet.buy[0]);
                } else if (itemBlockchain.packtype === "mystery") {
                    setActiveTypePack(1);
                    setAvail((myPackWlGet.pass_number[0] + myPackWlGet.pass_number[1]) - myPackWlGet.buy[1]);
                } else if (itemBlockchain.packtype === "infinity") {
                    setActiveTypePack(2);
                    setAvail(myPackWlGet.pass_number[1] - myPackWlGet.buy[2]);
                }
            }

            if (itemBlockchain.timestart == itemBlockchain.wltimeend) {
                let num = itemBlockchain.timestart == itemBlockchain.wltimeend && myPackWlNoGetLocal?.cooldown > Math.floor(Date.now() / 1000) ? 0 : 1;
                console.log("myPackWlNoGetLocal?.cooldown", myPackWlNoGetLocal?.cooldown);
                setYouCanBuy(num)
            }
        }
    }, [itemBlockchain, myPackWlGet]);

    return (
        <>
            {itemBlockchain ?
                <div className="Drop__card discont_content">
                    <div className={`Drop__menu__name ${item.rar}`}>
                        <div className={`${item.title}`}></div>
                    </div>


                    <div className="Drop__card__content ">
                        {itemBlockchain.count == 0 ?
                            <div className="sold__out" style={{ display: "block" }}><span>SOLD OUT</span></div>
                            : null
                        }
                        {/* Sale closed */}
                        {Math.floor(Date.now() / 1000) > itemBlockchain.timeend && itemBlockchain.count != 0 ?
                            <div className="sale__closed" style={{ display: "block" }}><span>SALE CLOSED</span></div>
                            : null
                        }
                        <div className="timer__block__drop">
                            {Math.floor(Date.now() / 1000) < itemBlockchain.timestart ?
                                <>
                                    <div className="state__drop">The sale will start in:</div>
                                    <div className='timer__drop'>
                                        <Countdown
                                            daysInHours

                                            date={itemBlockchain.timestart * 1000}
                                            onComplete={(e) => {
                                                updateTimer()
                                            }}

                                        />
                                    </div>
                                </>
                                : Math.floor(Date.now() / 1000) > itemBlockchain.timestart && Math.floor(Date.now() / 1000) < itemBlockchain.timeend ?
                                    <>
                                        <div className="state__drop">Sale will close in:</div>

                                        <div className={`timer__drop ${item.bg}`}>
                                            <Countdown
                                                daysInHours
                                                date={itemBlockchain.timeend * 1000}
                                                onComplete={(e) => {
                                                    updateTimer()
                                                }}
                                            />
                                        </div>
                                    </>

                                    :

                                    <>
                                        <div className="state__drop">the sale is over</div>
                                    </>


                            }


                        </div>
                        <div className="leaf__one__description">
                            {/* <div className='titlef' >Leaflynn</div> */}
                            <div className="block__contains">
                                <div className='title__leaf'>Contains:</div>
                                <button className='inf__leaf__c'>{item.contains}</button>
                                {item.contains2 != undefined ?
                                    <button className='inf__leaf__c violetta'>{item.contains2}</button>
                                    : null}
                            </div>
                            <div className="block__chances">
                                <div className='multi_leaf'>Drop chances:</div>
                                <div className="block__chances__fon">

                                    {item.dropChance.map(
                                        (drop) => {
                                            return (
                                                <div className={drop.class} id='inf__num'> <div className='inf__num'>{drop.type}</div> <div className=''>{drop.perc}%</div></div>
                                            )
                                        }
                                    )
                                    }
                                </div>

                            </div>
                        </div>

                        <div className={`${item.pack}`}>

                        </div>
                        {allReferralsVipGet && allReferralsVipGet.user || allReferralsVipYouGet && allReferralsVipYouGet.user ? <div className="discontIconDrop"><span>10% off</span></div> : null}

                        <div className="block__pack">
                            <div className='title__leaf'>Info:</div>
                            <div className="DropSupply fonDrop">
                                <div className="">Supply:</div>
                                <div className="supply">{itemBlockchain ? itemBlockchain.supply : 0}</div>
                            </div>
                            <div className="DropSold">
                                <div className="">Sold:</div>
                                <div className="sold">{itemBlockchain ? itemBlockchain.supply - itemBlockchain.count : 0}</div>
                            </div>
                            <div className="DropAvailable fonDrop">
                                <div className="">Total available:</div>
                                <div className="available__one">{itemBlockchain ? itemBlockchain.count : 0}</div>
                            </div>
                            <div className="DropAvailable">
                                <div style={{ overflow: 'hidden', textAlign: 'left' }} className="">Remaining claims:</div>
                                <div className="available__one">{youCanBuy}</div>
                            </div>
                        </div>

                        <div className="Group">
                            <div className="Craft__price">
                                <div className='name__wallet price'>
                                    <p>Price</p>
                                </div>
                                <div className="block__sum">
                                    <div className='icon__sum'>      
                                        <p>
                                        { (allReferralsVipGet && allReferralsVipGet.user) || (allReferralsVipYouGet && allReferralsVipYouGet.user) 
                                            ? (itemBlockchain.price * parseInt(value) * 0.9)
                                            : (itemBlockchain.price * parseInt(value))
                                        }
                                        </p>
                                        {/* <p>{allReferralsVipGet && allReferralsVipGet.user || allReferralsVipYouGet && allReferralsVipYouGet.user ? itemBlockchain.price * parseInt(value) * 0.9 : itemBlockchain.price * parseInt(value)}</p> */}
                                    </div>
                                    <div className='icon__spn__p'>
                                        <p>WAX</p>
                                    </div>
                                    <div className="icon__modal"><div className="price__icon"></div></div>
                                </div>
                            </div>
                            <div className="Claims">

                                {/* <div className="DropAvailable"> */}
                                {/* <div className="">Available Claims:</div> */}
                                {/* <div className="available__one">
                                        {console.log("{activeTypePack != null", itemBlockchain.buy)}
                                        {avail}
                                    </div> */}
                                {/* </div> */}
                                <div className="box__slots">
                                    <span onClick={() => decrement()} className='minus'></span>
                                    <div className="total__add">{value}</div>
                                    <span onClick={() => increment()} className='plus'></span>
                                </div>
                            </div>

                            {console.log("myPackWlGet?.pass_number[index]", myPackWlGet?.pass_number[index - 1])}
                            {console.log("[index]", index)}

                            {Math.floor(Date.now() / 1000) < itemBlockchain.timestart || Math.floor(Date.now() / 1000) > itemBlockchain.timeend || index !== 0 && myPackWlGet?.pass_number[index - 1] == undefined || index !== 0 && myPackWlGet?.pass_number[index - 1] == 0 ?
                                <button className='DropBuy disab' disabled>
                                    Purchase
                                </button>

                                : Math.floor(Date.now() / 1000) > itemBlockchain.timestart && Math.floor(Date.now() / 1000) < itemBlockchain.timeend && itemBlockchain && itemBlockchain.count > 0 ?
                                    Math.floor(Date.now() / 1000) < myPackWlGet?.cooldown[index] ?
                                        <button className='DropBuy disab' disabled>
                                            <Countdown
                                                daysInHours
                                                date={myPackWlGet?.cooldown[index] * 1000}
                                                onComplete={(e) => {
                                                    updateTimer();
                                                }}
                                            />
                                        </button>
                                        :
                                        Math.floor(Date.now() / 1000) < myPackWlNoGetLocal?.cooldown && itemBlockchain.timestart == itemBlockchain.wltimeend ?
                                            <button className='DropBuy disab' disabled>
                                                <Countdown
                                                    daysInHours
                                                    date={myPackWlNoGetLocal?.cooldown * 1000}
                                                    onComplete={(e) => {
                                                        updateTimer();
                                                    }}
                                                />
                                            </button> :
                                            <button className='DropBuy' onClick={() => buyPackTrans(allReferralsVipGet && allReferralsVipGet.user || allReferralsVipYouGet && allReferralsVipYouGet.user ? itemBlockchain.price * value * 0.9 : itemBlockchain.price * parseInt(value), itemBlockchain.packtype, value)} >
                                                Purchase
                                            </button>
                                    : <button className='DropBuy disab' disabled>
                                        Purchase
                                    </button>
                            }

                        </div>

                        {item.wl ?
                            myPackWlGet && myPackWlGet.user !== undefined && myPackWlGet?.pass_number[index - 1] ?
                                <div id='wList' className="notWhiteList">
                                    <span>Available for purchase.</span>
                                </div>
                                :
                                <div id='wList' className="notWhiteList no">
                                    <span>You're not on the whitelist</span>
                                </div>

                            :
                            itemBlockchain?.timestart == itemBlockchain?.wltimeend ?
                                <div id='wList' className="notWhiteList">
                                    <span>Available for purchase.</span>
                                </div>
                                :
                                null

                        }


                    </div>

                </div>
                : null}
        </>
    )
}


export default DropSingle;