import React from 'react';
import '../CraftDropNav/CraftDropNav.css';
import Drop from '../Drop/Drop';
import Craft from '../Craft/Craft';
import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

const CraftDropNav = ({ craftTrans, buyPackTrans, userInGameState, balanceDisp }) => {

  let { id } = useParams();

  useEffect(() => {
  }, [id]);


  const [activeMenuCraftDrop, setActiveMenuCraftDrop] = useState("CRAFT1");

  const handleMenuClickCraftDrop = (menu) => {
    setActiveMenuCraftDrop(menu);
    localStorage.setItem('activeMenuCraftDrop', menu);
  };

  useEffect(() => {
    const savedActiveMenuCraftDrop = localStorage.getItem('activeMenuCraftDrop');
    if (savedActiveMenuCraftDrop) {
      setActiveMenuCraftDrop(savedActiveMenuCraftDrop);
    }
  }, []);

  useEffect(() => {
    const savedActiveMenuCraftDrop = localStorage.getItem('activeMenuCraftDrop');
    if (savedActiveMenuCraftDrop) {
      setActiveMenuCraftDrop(savedActiveMenuCraftDrop);
    } else {
      setActiveMenuCraftDrop("CRAFT1");
      localStorage.setItem('activeMenuCraftDrop', "CRAFT1");
    }
  }, []);


  useEffect(() => {
    if (id == "craft") {
      setActiveMenuCraftDrop("CRAFT1");
    } else if (id == "drop") {
      setActiveMenuCraftDrop("DROP1");
    }
  }, [id]);


  return (
    <div className="main__content">


      <div className="contentBankpage">
        <div className="nav__top">
          <button
            className={`bt__nav ${activeMenuCraftDrop === "CRAFT1" ? "active__top" : ""}`}
            onClick={() => handleMenuClickCraftDrop("CRAFT1")} ><Link to="/craftdrop/craft" >Craft</Link></button>
          <button
            className={`bt__nav ${activeMenuCraftDrop === "DROP1" ? "active__top" : ""} `}
            onClick={() => handleMenuClickCraftDrop("DROP1")}><Link to="/craftdrop/drop" >Drop</Link>
          </button>
        </div>
        {activeMenuCraftDrop === "CRAFT1" && userInGameState && userInGameState.user !== undefined && (
          <div className="CRAFT1"><Craft craftTrans={craftTrans} userInGameState={userInGameState} balanceDisp={balanceDisp} /></div>
        )}
        {activeMenuCraftDrop === "DROP1" && (
          <div className="DROP1"><Drop buyPackTrans={buyPackTrans} userInGameState={userInGameState} /></div>
        )}

      </div>
    </div>

  )
}


export default CraftDropNav;

