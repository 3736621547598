import React, { useState, useEffect } from 'react'
import '../Modal/Modal.css'
import { useNavigate } from 'react-router-dom'
import { Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/scrollbar'
import 'swiper/css/mousewheel'
import { Mousewheel } from 'swiper'


const Modal = ({ props, active, setSelectedSlides, availSlots, setActive, allSpinnerInGame, selectedSlides, children, activeButtonSpin, modalContent, activeSpinner, buyInStorageTrans, nowScore }) => {
  const [activeSpin, setActiveSpin] = useState(1);
  const [selectedSlide, setSelectedSlide] = useState(1);
  const handleSlideClick = (index) => {
    setSelectedSlide(index);
  };

  const slideData = [
    { id: 0, className: 'slide__player_1' },
    { id: 1, className: 'slide__player_2' },
    { id: 2, className: 'slide__player_3' },

  ]

  useEffect(() => {
    let iconClass = '';
    let priceContent = '';
    let iconClassRuna = '';
    let priceContentRuna = '';
    let iconClassTicket = '';
    let priceContentTicket = '';


    // 1
    // 5
    // 10
    // 20
    // 40
    // 80

    if (activeSpinner?.rarity === 0) {
      iconClass = 'icon__card__Magmus';
      // iconClass = 'promoContentBlock';
      priceContent = (process.env.REACT_APP_DEF_PRICE / 5) * (1 + (1 - process.env.REACT_APP_DEF))
      iconClassRuna = 'icon__card__Promo';
      priceContentRuna = (process.env.REACT_APP_DEF_PRICE / 5) * 2 * (1 + (1 - process.env.REACT_APP_DEF));
      iconClassTicket = 'iconClassTicket boxIncNumber';
      priceContentTicket = 25 * (1 + (1 - process.env.REACT_APP_DEF));
    } else if (activeSpinner?.rarity === 1) {
      iconClass = 'icon__card__Steelade';
      priceContent = process.env.REACT_APP_DEF_PRICE * (1 + (1 - process.env.REACT_APP_DEF));
      iconClassRuna = 'icon__card__Common';
      priceContentRuna = process.env.REACT_APP_DEF_PRICE * 2 * (1 + (1 - process.env.REACT_APP_DEF));
      iconClassTicket = 'iconClassTicket boxIncNumber';
      priceContentTicket = 25 * (1 + (1 - process.env.REACT_APP_DEF));
    } else if (activeSpinner?.rarity === 2) {
      iconClass = 'icon__card__Leaflynn';
      priceContent = process.env.REACT_APP_DEF_PRICE * 2 * (1 + (1 - process.env.REACT_APP_DEF));
      iconClassRuna = 'icon__card__Uncommon';
      priceContentRuna = process.env.REACT_APP_DEF_PRICE * 4 * (1 + (1 - process.env.REACT_APP_DEF));
      iconClassTicket = 'iconClassTicket boxIncNumber';
      priceContentTicket = 25 * (1 + (1 - process.env.REACT_APP_DEF));
    } else if (activeSpinner?.rarity === 3) {
      iconClass = 'icon__card__Frostee';
      priceContent = process.env.REACT_APP_DEF_PRICE * 4 * (1 + (1 - process.env.REACT_APP_DEF));
      iconClassRuna = 'icon__card__Rare';
      priceContentRuna = process.env.REACT_APP_DEF_PRICE * 8 * (1 + (1 - process.env.REACT_APP_DEF));
      iconClassTicket = 'iconClassTicket boxIncNumber';
      priceContentTicket = 25 * (1 + (1 - process.env.REACT_APP_DEF));
    } else if (activeSpinner?.rarity === 4) {
      iconClass = 'icon__card__Violetta';
      priceContent = process.env.REACT_APP_DEF_PRICE * 8 * (1 + (1 - process.env.REACT_APP_DEF));
      iconClassRuna = 'icon__card__Epic';
      priceContentRuna = process.env.REACT_APP_DEF_PRICE * 16 * (1 + (1 - process.env.REACT_APP_DEF));
      iconClassTicket = 'iconClassTicket boxIncNumber';
      priceContentTicket = 25 * (1 + (1 - process.env.REACT_APP_DEF));
    } else if (activeSpinner?.rarity === 5) {
      iconClass = 'icon__card__Sunshine';
      priceContent = process.env.REACT_APP_DEF_PRICE * 16 * (1 + (1 - process.env.REACT_APP_DEF));
      iconClassRuna = 'icon__card__Legend'
      priceContentRuna = process.env.REACT_APP_DEF_PRICE * 32 * (1 + (1 - process.env.REACT_APP_DEF));
      iconClassTicket = 'iconClassTicket boxIncNumber';
      priceContentTicket = 25 * (1 + (1 - process.env.REACT_APP_DEF));
    } else if (activeSpinner?.rarity === 6) {
      iconClass = 'icon__card__Jing';
      // iconClass = 'promoContentBlock';
      priceContent = (process.env.REACT_APP_DEF_PRICE / 5) * (1 + (1 - process.env.REACT_APP_DEF));
      iconClassRuna = 'promoContentBlock';
      priceContentRuna = (process.env.REACT_APP_DEF_PRICE / 5) * 2 * (1 + (1 - process.env.REACT_APP_DEF));
      iconClassTicket = 'iconClassTicket boxIncNumber';
      priceContentTicket = 25 * (1 + (1 - process.env.REACT_APP_DEF));
    }

    setActiveSpin({ iconClass, priceContent, iconClassRuna, priceContentRuna, iconClassTicket, priceContentTicket });
  }, [activeSpinner]);

  const onClose = () => {
    setActive(false);
  };

  const [count, setCount] = useState(1);

  const [buyNameItem, setBuyNameItem] = useState(1);
  const [buyPriceItem, setBuyPriceItem] = useState(1);
  useEffect(() => {
    if (selectedSlide == 1) {
      setBuyNameItem("spinney");
      setBuyPriceItem(activeSpin.priceContent);
    } else if (selectedSlide == 2) {
      setBuyNameItem("runes");
      setBuyPriceItem(activeSpin.priceContentRuna);
    } else if (selectedSlide == 3) {
      setBuyNameItem("ticket");
      setBuyPriceItem(activeSpin.priceContentTicket);
    }
  }, [selectedSlide]);




  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);

  };

  const handleDecrement = () => {
    setCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));

  };
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue === '' || (!isNaN(inputValue) && parseInt(inputValue, 10) >= 0)) {
      setCount(inputValue === '' ? '' : parseInt(inputValue, 10));
    }
  };





  return (
    <div id="modal1" className={active ? "modal__content activerun" : "modal__content"} onClick={() => setActive(false)}>
      <div className="">
        <div className={`modal boxFirstStor ${active ? 'activerun' : ''}`} onClick={(event) => event.stopPropagation()}>
          <div className="title_purchase">Mini Market</div>
          <div className="textMarket">Mini-market uses your Spinney storage balance</div>
          <div className="boxStor">
            <div className="leftAllStop">
              <div className="textMarket">Current storage balance</div>
              <div className="storBalance">{activeSpinner.vip && activeSpinner.runes !== 0 ? nowScore.toFixed(4) : (activeSpinner.storage / 10000).toFixed(4)}</div>
            </div>
            <div className="rightIconSpn">
              <div className="iconTokenStor"></div>
              <div className="spnText">SPN</div>
            </div>
          </div>

        </div>
        <div className={`modal boxMarket ${active ? 'activerun' : ''}`} onClick={(event) => event.stopPropagation()}>
          {activeSpin && (
            <div className="sliderModalMarket">
              <Swiper
                id="spinn__sliderMarket"
                modules={[Scrollbar, Mousewheel]}
                mousewheel={true}
                spaceBetween={8}
                direction={'horizontal'}
                scrollbar={{ draggable: true }}
                breakpoints={{
                  320: {
                    slidesPerView: 1
                  },
                  610: {
                    slidesPerView: 1
                  },
                  1200: {
                    slidesPerView: 3
                  }
                }}
              >
                <SwiperSlide onClick={() => {
                  handleSlideClick(1);
                  setSelectedSlide(1);
                }}
                  className={`${activeSpin.iconClass} slide__Spinney  ${selectedSlide === 1 ? 'selected' : ''}`}
                // style={{ width: '100px', height: '146px' }}
                >
                  {/* <div
                   
                  ></div> */}
                </SwiperSlide>
                <SwiperSlide onClick={() => {
                  handleSlideClick(2);
                  setSelectedSlide(2);
                }}
                  className={`${activeSpin.iconClassRuna} slide__Runa ${selectedSlide === 2 ? 'selected' : ''}`}
                // style={{ width: '100px', height: '146px' }}
                >
                  {/* <div
                    
                  ></div> */}
                </SwiperSlide>
                <SwiperSlide onClick={() => {
                  handleSlideClick(3);
                  setSelectedSlide(3);
                }}
                  className={`${activeSpin.iconClassTicket} slide__Ticket ${selectedSlide === 3 ? 'selected' : ''}`}
                // style={{ width: '100px', height: '146px' }}
                >
                  {/* <div
                    
                  ></div> */}
                </SwiperSlide>


              </Swiper>
            </div>
          )}
        </div>

        <div className={`modal ${active ? 'activerun' : ''}`} onClick={(event) => event.stopPropagation()}>
          {activeSpin && (
            <div className="container__modal">
              {selectedSlide === 1 ? (
                <div className={activeSpin.iconClass}></div>
              ) : selectedSlide === 2 ? (
                <div className={activeSpin.iconClassRuna}></div>
              ) : (
                <div className={activeSpin.iconClassTicket}></div>
              )}
              <div className="cintainer__purchase">
                {selectedSlide === 3 ? (
                  <div className="title_purchase ticketTop">Purchase</div>
                ) : <div className="title_purchase">Purchase</div>}
                {selectedSlide === 3 ? (
                  <div className='boxIncNumber'>
                    <div className="text__boxIncNumber">Quantity</div>
                    <div className="box__slots">
                      <span className='minus' onClick={handleDecrement}></span>
                      <input
                        type="text"
                        className="total__add"
                        value={count}
                        onChange={handleInputChange}
                      />
                      <span className='plus' onClick={handleIncrement}></span>
                    </div>
                  </div>
                ) : null}
                <div className="wallet__content">
                  <div className="wallet__info">
                    <div className="name__wallet price">
                      <p>Price</p>
                    </div>
                    <div className="block__sum">
                      <div className="icon__sum">

                        {selectedSlide === 1 ?
                          (
                            <p>{activeSpin.priceContent}</p>
                          ) : selectedSlide === 2 ? (
                            <p>{activeSpin.priceContentRuna}</p>
                          ) : (
                            <p>{(activeSpin.priceContentTicket * count).toFixed(4)}</p>
                          )}

                      </div>
                      <div className="icon__spn__p">
                        <p>SPN</p>
                      </div>
                      <div className="icon__modal">
                        <div className="price__icon"></div>
                      </div>
                    </div>
                    {selectedSlide === 3 ? (
                      <div className="q qTicketBottom">Please confirm your purchase. Coins will be charged from your storage.</div>
                    ) : <div className="q">Please confirm your purchase. Coins will be charged from your storage.</div>}
                  </div>
                </div>
                <div className="btn__modal">
                  <button onClick={onClose}>
                    <span className='tokenDiscont'>Cancel</span>
                  </button>
                  <button onClick={() => buyInStorageTrans(activeSpinner, buyNameItem, buyPriceItem, count)}>
                    <span>Confirm</span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

    </div>
  );
};

export default Modal;
